import React, { useCallback, useEffect, useState } from 'react';
import { Button, Link, Typography, Dialog, DialogActions, DialogTitle, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Loader from '../../../components/src/Loader';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0, 17),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(0, 13),
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(0, 9),
    },
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(0, 12),
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 44,
    backgroundColor: '#38006D',
    color: '#ffff',
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 600,
    padding: '15px 0',
    '&:hover': {
      background: '#38006D',
    },
  },
  otpInputField: {
    width: '67px',
    height: '67px',
    textAlign: 'center',
    margin: '0.7rem',
    border: '0.6px solid #37006E',
    borderRadius: '6px',
    fontSize: '1.45rem',
    [theme.breakpoints.only('xs')]: {
      width: '45px',
      height: '55px',
    },
    [theme.breakpoints.only('md')]: {
      width: '60px',
      height: '60px',
    },
  },
  otpTitleText: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 600,
    fontSize: '35px',
    lineHeight: '43px',
    color: '#37006E',
    paddingBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      lineHeight: '33px',
    },
  },
  otpContentText: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#968C8C',
    marginBottom: '2rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '16px',
      lineHeight: '25px',
    },
  },
  signup_with_fb_gl_text: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 500,
    fontSize: '20px',
    textAlign: 'center',
    color: '#4C8EB5',
    margin: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  tms_and_condi_text: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 500,
    fontSize: '20px',
    textAlign: 'center',
    color: '#37474F',
    margin: '1.5rem 0',
    [theme.breakpoints.only('xs')]: {
      fontSize: '11px',
      margin: '1.4rem 0',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '14px',
      margin: '0.7rem 0',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '18px',
      margin: '0.5rem 0',
    },
  },
  t_c_policy_text: {
    color: '#ec6b47',
    cursor: 'pointer',
  },
  otpPhoneNumber: {
    color: '#41479B',
  },
  numberChangeBtn: {
    color: '#BA68C8',
    fontSize: '11px',
    cursor: 'pointer',
  },
  modelContent: {
    padding: '2rem',
    borderRadius: '13px',
  },
}));

interface Props {
  otpVarification: (otp: string) => void;
  resentOtpRequest: (phoneNumber: any) => void;
  navigationBtnFnc: (path: any) => void;
  pageManageInLocalstore: (
    mobileInputPage: boolean,
    otpInputPage: boolean,
    profileDetailsInputPage: boolean
  ) => void;
  handleContinue: (otp: string) => void;
  loading: boolean;
  inValidOTP: any;
  isInValidOTP: boolean;
  expireOTP: any;
  isExpireOTP: any;

}
let currentOTPIndex: number = 0;
const OtpInputPage = ({
  otpVarification,
  resentOtpRequest,
  pageManageInLocalstore,
  navigationBtnFnc,
  handleContinue,
  loading,
  inValidOTP,
  isInValidOTP,
  expireOTP,
  isExpireOTP,
}: Props) => {
  const classes = useStyles();
  const [t] = useTranslation(["translation", "signUpLoginLng"]);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [counter, setCounter] = React.useState(60);
  const [otpError, setOtpError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {

    let otpTime = localStorage.getItem('otpTime')
    if (otpTime) {
      let time = (parseInt(otpTime) - 1);
      setCounter(time);
    }

  }, [])

  useEffect(() => {
    const timer: any = counter > 0 && setInterval(() => {
      localStorage.setItem('otpTime', JSON.stringify(counter))
      handleIncrement()
    }, 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleIncrement = useCallback(() => {
    setCounter(counter - 1);
  }, [counter]);

  const handelOtp = (e: any) => {
    e.preventDefault();
    if (otp[0] === '' || otp[1] === '' || otp[2] === '' || otp[3] === '') {
      setOtpError(true);
    } else {
      setOtpError(false);
      setOpen(true);
    }
  };

  const handelResendOtpRequst = () => {
    setOtp(new Array(4).fill(''))
    const phonenNmber = localStorage.getItem('inputPhoneNumber');
    if (phonenNmber != null) {
      resentOtpRequest(phonenNmber);
      setCounter(60);
    }
  };
  const handelChangeNumber = () => {
    localStorage.clear();
    pageManageInLocalstore(true, false, false);
    navigationBtnFnc('UserSignUpScreenWeb');
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOtpCode = () => {
    otpVarification(otp.join(''));
    setOpen(false);
  }
  const handleContinueBtn = (otp: any) => {
    handleContinue(otp);
    setOpen(false);
  }

  const handleChange = ({ target, }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newOTP: string[] = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1)

    if (!value) { setActiveOTPIndex(currentOTPIndex - 1) }
    else { setActiveOTPIndex(currentOTPIndex + 1) }
    setOtp(newOTP);
    setOtpError(false);
  }

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex])

  const inputOnKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    currentOTPIndex = index
    if (key === "Backspace") setActiveOTPIndex(currentOTPIndex - 1);
  }

  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}
      >
        <Loader loading={loading} />
      </Grid>
    );
  }

  return (

    <div className={classes.paper}>
      <Typography
        component="h1"
        variant="h5"
        className={classes.otpTitleText}
      >
        {t("signUpLoginLng:OTPVerification")}
      </Typography>

      <Typography component="h1" className={classes.otpContentText}>
        {t("signUpLoginLng:otpNumText")}{' '}
        <span className={classes.otpPhoneNumber}>
          +{localStorage.getItem('inputPhoneNumber')}
        </span>{' '}
        <Link
          className={classes.numberChangeBtn}
          onClick={handelChangeNumber}
        >
          {t("signUpLoginLng:change")}
        </Link>
      </Typography>

      <form onSubmit={handelOtp} className={classes.form} noValidate>

        {otp.map((_, index) => {
          return (
            <input
              key={index}
              ref={index === activeOTPIndex ? inputRef : null}
              name="otp"
              className={classes.otpInputField}
              type="number"
              value={otp[index]}
              onChange={handleChange}
              onKeyDown={(e: any) => inputOnKeyDown(e, index)}
              inputMode="numeric"
            />
          );
        })}

        {otpError && (
          <Alert severity="warning">Please Enter the valid OTP!</Alert>
        )}
        {isInValidOTP && (
          <Alert severity="error">{inValidOTP}</Alert>
        )}
        {isExpireOTP && (
          <Alert severity="error" variant='filled'>{expireOTP}Mobile number is Not valid</Alert>
        )}

        <div>
          <Typography className={classes.signup_with_fb_gl_text}>
            0:{counter}
          </Typography>

          <Typography className={classes.tms_and_condi_text}>
            {t("signUpLoginLng:resendOTP1")}
            <Button
              variant='text'
              onClick={handelResendOtpRequst}
              className={classes.t_c_policy_text}
              disabled={counter > 5}
            >
              {t("signUpLoginLng:resendOTP2")}
            </Button>
          </Typography>
        </div>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.submit}
        >
          {t("signUpLoginLng:verifyButton")}
        </Button>
      </form>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
      >
        <div className={classes.modelContent}>
          <DialogTitle id="form-dialog-title">
            {t("signUpLoginLng:signUpModal")}{' '}
          </DialogTitle>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Button variant="outlined" onClick={handleOtpCode} color="primary">
                {t("signUpLoginLng:signUpButton")}
              </Button>
              <Button onClick={() => handleContinueBtn(otp.join(''))} variant="contained" color="primary">
                {t("signUpLoginLng:ContinueButton")}
              </Button>
            </Grid>
          </DialogActions>
        </div>
      </Dialog>

      <div>
        <Typography className={classes.tms_and_condi_text}>
          {t("signUpLoginLng:T&CPrivacyPolicy1")}{' '}
          <span className={classes.t_c_policy_text}>
            <Link style={{ cursor: 'pointer', color: '#ec6b47' }} onClick={() => navigationBtnFnc('TermsAndConditionsWeb')}>{t("signUpLoginLng:T&CPrivacyPolicy2")}</Link> <Link style={{ cursor: 'pointer', color: '#ec6b47' }} onClick={() => navigationBtnFnc('ContentManagementPrivacyPolicy')} >{t("signUpLoginLng:T&CPrivacyPolicy3")}</Link>
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default OtpInputPage;
