// Customizable Area Start
import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { createStyles,withStyles, Theme } from "@material-ui/core/styles";
import ContentManagementController, {Props} from "./ContentManagementController";
import Loader from '../../../components/src/Loader';
import { withTranslation } from 'react-i18next';

const styles = (theme:Theme) => createStyles({
  
  Textstyle: {
    fontFamily: `'Poppins', sans-serif`,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
    fontWeight: 100,
    fontSize: "14px",
    },
  },
  TextColor: {
    color:"#f00",
  },
  
  RootBoxContainerStyle: {
    marginTop: 100,
    marginLeft: 55.5,
    marginRight: 55.5,
    background: "#FFFFFF",
    paddingBottom: 50,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 25,
      marginRight:25,
    },
  },
  
  TitleBoxContainerStyle: {
    // marginLeft: 43,
    marginTop: 45,
    marginBottom: 57,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    marginTop: 10,
    marginBottom: 25,
    },
  },
  
  HeadingStyle: {
    fontFamily: `'Montserrat', sans-serif`,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "39px",
    lineHeight: "48px",
    color: "#455A64",    
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "30px",
    },
  },
  
  ContentContainerStyle: {
              marginLeft: 59,
              marginRight: 45,
              marginBottom: 203.86,
              [theme.breakpoints.down("sm")]: {
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 0,
              },
  },
  linkResStyle: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      },
  }
  
});

class AboutUsWeb extends ContentManagementController {
  constructor(props:Props){
    super(props)
  }
 
  render() {
    if (this.state.loading) {
      return (
          <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ height: '100vh' }}
          >
              <Loader loading={this.state.loading} />
          </Grid>
      );
  }
    const { classes, t } = this.props;
    return (
      <Grid container justifyContent="center" alignItems="center">
        <div className={classes.RootBoxContainerStyle}>
          <div className={classes.TitleBoxContainerStyle}>
            <Typography className={classes.HeadingStyle}>{t("About_Us")}</Typography>
          </div> 
          {this.state.aboutUs?.data?.map((x:any, i:any)=>{
            return(
                <>
                   <h3 id="terms-contents" dangerouslySetInnerHTML={{__html:  x?.attributes?.title}}/> 
                   <div style={{marginLeft:8}}id="terms-contents" dangerouslySetInnerHTML={{__html:  x?.attributes?.description}}/> 
                </>
            )
          })}
      
        </div>
       
      </Grid>
    );
  }
}
export default withStyles(styles, { withTheme: true })(withTranslation('footerLng')(AboutUsWeb));
