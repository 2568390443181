import React from 'react';
import { Select, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';



const RelationSelectField = ({ instance, MenuProps }: any) => {
    const classes = useStyles();
    return (
        <div className={classes.foodBasketTypeTextField}>
            <Select
                disableUnderline
                data-testid="relationField"
                value={instance.state.relationName}
                onChange={(event: any) => instance.handelRelationSelectField(event)}
                fullWidth
                className={`${classes.selectInput} ${classes.menuItemText}`}
                classes={{ root: classes.selectRoot }}
                MenuProps={MenuProps}
            >
                <MenuItem value="Select" className={classes.menuItemText}>
                    Select
                </MenuItem>
                {/* istanbul ignore next */}
                {instance.state.relation.map((item: any, key: any) => {
                    return (
                        <MenuItem
                            key={key}
                            value={item.id}
                            className={classes.menuItemText}
                        >
                            {item.attributes.relation}
                        </MenuItem>
                    )
                })}
            </Select>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({

    selectInput: {
        padding: '0 0.7rem'
    },
    foodBasketTypeTextField: {
        borderRadius: 8,
        border: "double 1px transparent",
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(205deg,#41479B,#FF3939)",
        backgroundClip: "content-box, border-box",
        backgroundOrigin: "border-box",
        [theme.breakpoints.down("sm")]: {
            marginRight: "auto",
            marginBottom: 16,
            marginLeft: "auto",
        },
        height: '55px',
    },
    menuItemText: {
        fontFamily: `'Montserrat', sans-serif`,
        fontStyle: "normal",
        fontWeight: 500,
        backgroundColor: 'transparent',
        height: '100%',
    },
    selectRoot: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&:focus': {
            backgroundColor: 'transparent'
        },
    },
}));

export default RelationSelectField

