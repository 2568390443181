import React from 'react'
import { Select, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';





const CitySelectfield = ({ instance, MenuProps }: any) => {
    const classes = useStyles();
    return (
        <div className={classes.foodBasketTypeTextField}>
            <Select
                disableUnderline
                value={instance.state.cityName}
                data-testid="cityField"
                onChange={(event: React.ChangeEvent<{ value: any }>) => instance.handelCitySelectField(event)}
                fullWidth
                disabled={instance.state.stateName === 'Select' ? true : false}
                className={`${classes.selectInput} ${classes.menuItemText}`}
                classes={{ root: classes.selectRoot }}
                MenuProps={MenuProps}
            >
                <MenuItem value="Select" className={classes.menuItemText}>
                    Select
                </MenuItem>
                {instance.CitiesName.map((name: any, key: any) => {
                    return (
                        <MenuItem
                            key={key}
                            value={name}
                            className={classes.menuItemText}
                        >
                            {name}
                        </MenuItem>
                    )
                })}
            </Select>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    selectRoot: {
        '&:focus': {
            backgroundColor: 'transparent'
        },
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    selectInput: {
        padding: '0 0.7rem'
    },
    menuItemText: {
        fontFamily: `'Montserrat', sans-serif`,
        fontStyle: "normal",
        fontWeight: 500,
        backgroundColor: 'transparent',
        height: '100%',
    },
    foodBasketTypeTextField: {
        height: '55px',
        borderRadius: 8,
        border: "double 1px transparent",
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(205deg,#41479B,#FF3939)",
        backgroundClip: "content-box, border-box",
        backgroundOrigin: "border-box",
        [theme.breakpoints.down("sm")]: {
            marginBottom: 16,
            marginRight: "auto",
            marginLeft: "auto",
        },
    },
}));

export default CitySelectfield

