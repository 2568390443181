import React from 'react';
import Loader from '../../../components/src/Loader';
import HealthyFoodBasketWebController from './HealthyFoodBasketWebController';
import { Typography, Grid, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { topHeaderBanner, sideBarBanner } from './assets';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../components/src/SideNavbarWeb';
import HealthProductList from '../../../components/src/healthComponents/HealthProductList';
import PositiveNagativeHealthPoint from '../../../components/src/healthComponents/PositiveNagativeHealthPoint';
import CustomDeleteAlartDailogBox from '../../../components/src/CustomDeleteAlartDailogBox';
import { withTranslation } from 'react-i18next';



class HealthyFoodDetailsPageWeb extends HealthyFoodBasketWebController {
    // Customizable Area Start
    render() {
        const {
            foodProductDetailsLoader,
            foodProductDetails,
            foodProductNutritionValue
        } = this.state;
        const { classes, t } = this.props;
        if (foodProductDetailsLoader) {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <Loader loading={foodProductDetailsLoader} />
                </Grid>
            );
        }

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>                    
                        <img src={topHeaderBanner} alt="bgImage" style={{ width: "100%" }} className={classes.favTopBanner} />

                        <div className={classes.textContainer}>
                            <div className={classes.searchResultText}>
                                <span className={classes.FoodTypeUnderline}>{foodProductDetails?.attributes?.order_name}</span>
                                <IconButton aria-label="delete" className={classes.deleteIconBtn} data-testid="deleteBtn" onClick={() => this.handleModalOpen(foodProductDetails?.attributes?.id)}>
                                    <DeleteIcon />
                                </IconButton>
                                <CustomDeleteAlartDailogBox isOpen={this.state.openFoodBasketDeleteModal} cancleFunction={this.handleModalClose} deleteFunction={this.deleteFoodBasket} deleteId={this.state.foodBasketDeleteId} />

                            </div>
                            <Typography className={classes.backButtonText} data-testid="backButton" onClick={() => this.navigationBtnFnc('HealthyFoodBasket')}>
                                {t("Back")}
                            </Typography>
                        </div>
                        <div className={classes.foodBasketContainer}>
                            <PositiveNagativeHealthPoint foodProductNutritionValue={foodProductNutritionValue} />
                            <div style={{ margin: '3rem 0' }}>
                                <Typography className={classes.TotalProductText}>{t("Total_Products")}: {foodProductDetails?.attributes?.product_count}</Typography>
                                {
                                    foodProductDetails?.attributes?.order_id?.data.map((item: any, key: any) => {
                                        return (
                                            <HealthProductList
                                                addToCompareProducts={this.addToCompareProducts}
                                                item={item}
                                                goToProductDetailsPageFunction={this.goToProductDetailsPage}
                                                key={key}
                                                deleteIndividualFoodBasketProduct={this.deleteIndividualFoodBasketProduct}
                                                handleIndividualFoodBasketProductDeleteModal={this.handleIndividualFoodBasketProductDeleteModal}
                                                openIndividualFoodBasketProductDeleteModal={this.state.openIndividualFoodBasketProductDeleteModal}
                                                handleModalClose={this.handleModalClose}
                                                orderId={foodProductDetails?.attributes?.id}
                                            />
                                        )
                                    }
                                    )
                                }
                            </div>
                        </div>
                    </Grid >
                </Grid >

            </div >
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        // css for textContainer
        textContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 25px 0 25px',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: 5,
                marginRight: 5,
            },
        },
        searchResultText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "33px",
            color: "#37006E",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "12px",
            },

        },
        backButtonText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF5050",
            cursor: 'pointer',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        FoodTypeUnderline: {
            textUnderlineOffset: "0.5rem",
            textDecoration: "underline",
            textDecorationColor: "#fb951a61",
        },

        // css for foodBasketContainer
        foodBasketContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },

        TotalProductText: {
            color: '#455A64',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontSize: '25px',
        },

        deleteIconBtn: {
            color: '#FF3939', border: '1px solid #FF784B',
            width: '40px',
            height: '40px',
            marginLeft: '1rem'
        },
        favTopBanner: {
            [theme.breakpoints.only("sm")]: {
                marginTop: "15px"
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: "7px"
            },
        },


    });
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    withTranslation('HealthyFoodBasketWebLang')(HealthyFoodDetailsPageWeb)
);