import React from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    bmiValueText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "35px",
        color: "#FFFFFF",
        [theme.breakpoints.down("sm")]: {
            fontSize: '21px',
        },
    },
    bmiText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "15px",
        color: "#FCC300",
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px',
        },
    },
    underWeightText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        color: "#FFFFFF",
        textTransform: "capitalize",
        [theme.breakpoints.down("sm")]: {
            fontSize: '11px',
        },
    },
    circleParentDiv: {
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        border: '5px solid white',
        position: 'relative',
        margin: '0 auto',
        [theme.breakpoints.down("sm")]: {
            width: '130px',
            height: '130px',
        },
        [theme.breakpoints.down("xs")]: {
            width: '120px',
            height: '120px',
        },
    },
    circleDiv: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    }
}));

export const BmiReaultGradeShowCircle = ({ BmiProfileDetails }: any) => {
    const classes = useStyles();
    return (
        <div className={classes.circleParentDiv}>
            <div className={classes.circleDiv}>
                <Typography className={classes.bmiValueText}>{BmiProfileDetails?.attributes?.bmi_result}</Typography>
                <Typography
                    className={classes.bmiText}>BMI</Typography>
                <Typography className={classes.underWeightText}>{BmiProfileDetails?.attributes?.bmi_status.replaceAll("_", " ")}</Typography>
            </div>
        </div>
    )
}
