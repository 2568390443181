import React from 'react';
// Customizable Area Start
import FavouriteSmartSearchController, { configJSON } from './FavouriteSmartSearchController';
import { Typography, Grid, IconButton, Button, Chip } from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { favouriteSmartSearchTopBg, NoProductFoundImg } from './assets';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Loader from '../../../components/src/Loader';
import { withTranslation } from 'react-i18next';
import { sanitizeFoodType } from '../../../components/src/utils';
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3.5rem 0',
            },
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        textContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 25px 1.5rem 25px',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: 5,
                marginRight: 5,
            },
        },
        foodSearchContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        foodSearchText: {
            color: '#455A64',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '22px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '16px',
            },
        },
        TotalfoodSearchText: {
            color: '#41479B',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '20px',
            [theme.breakpoints.down("sm")]: {
                fontSize: "15px",
            },
        },
        foodSearchProductText: {
            color: '#757D8A',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '10px'
        },
        newSmartSrcBtn: {
            backgroundColor: '#FF5050',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            color: '#FFFFFF',
            borderRadius: '8px',
            textTransform: 'capitalize',
            '&:hover': {
                backgroundColor: '#FF5050',
            },
        },

        searchResultText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "33px",
            color: "#37006E",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "15px",
                lineHeight: "12px",
            },

        },
        TextUnderline: {
            textUnderlineOffset: "0.5rem",
            textDecoration: "underline",
            textDecorationColor: "#fb951a61",
        },

        favouriteSmartSearchContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },


        productQualityContainer: {
            border: '1px solid #37006E',
            borderRadius: '10px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        tagList: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        searchResultChips: {
            border: "double 1.4px transparent", borderRadius: 23,
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(180deg, #41479B,#FB951A)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
        },
        chipLabel: {
            margin: 5,
            fontFamily: `'Montserrat', sans-serif`,
            fontSize: '14px',
            fontWeight: 600,
            letterSpacing: '0em',
            textAlign: 'left',
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
            },
        },
        noProductContainer: {

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: 'center',
            paddingTop: 50,
            paddingBottom: 50,

            [theme.breakpoints.down("lg")]: {
                paddingTop: 40,
                paddingBottom: 40,
            },
            [theme.breakpoints.down("md")]: {
                paddingTop: 25,
                paddingBottom: 25,
            },
            [theme.breakpoints.down("sm")]: {
                paddingTop: 15,
                paddingBottom: 15,
            },
            [theme.breakpoints.down("xs")]: {
                paddingTop: 5,
                paddingBottom: 5,
            },
        },
        noProductImage: {
            width: 200,
            height: 250,
            [theme.breakpoints.down("md")]: {
                width: 150,
                height: 200,
            },
            [theme.breakpoints.down("xs")]: {
                width: 100,
                height: 150,
            },
        },
        noProductTitle: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: "117.5%",
            color: '#FF3939',
            textAlign: "center",
            padding: 50,
            [theme.breakpoints.down("lg")]: {
                padding: 35,
            },
            [theme.breakpoints.down("md")]: {
                padding: 25,
            },
            [theme.breakpoints.down("sm")]: {
                padding: 15,
            },
            [theme.breakpoints.down("xs")]: {
                padding: 10,
            },
        },

        modelContent: {
            padding: '2rem',
            borderRadius: '1rem',
            [theme.breakpoints.down("sm")]: {
                padding: '1rem',
            },
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1.5),
            top: theme.spacing(2),
            color: '#455A64',
            border: '2px solid #38006D',
            padding: '2px'

        },
        notificationText: {
            textAlign: 'center',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "33px",
            color: "#455A64",
            [theme.breakpoints.down("sm")]: {
                fontSize: "13px",
                lineHeight: "12px",
            },
        },
        deleteDilogBoxBtn: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: "17px",
            lineHeight: "28px",
            width: 100,
            height: 40,
            borderRadius: '0.5rem',
            backgroundColor: '#12125c',
            color: '#ffff',
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "23px",
                width: 80,
                height: 31,
            },
            '&:hover': {
                background: '#12125c',
                border: 'none',
            },

        },
        cancelDilogBoxBtn: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: "17px",
            lineHeight: "28px",
            minWidth: 100,
            height: 40,
            borderRadius: '0.5rem',
            color: '#12125c',
            border: '2px solid #12125c',
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "23px",
                width: 80,
                height: 30,
            },
            '&:hover': {
                background: '#ffff',
            },

        },
        modelContentLogin: {
            padding: '2rem',
            borderRadius: '1rem',
        },


    });
// Customizable Area Stop
class FavouriteSmartSearchWeb extends FavouriteSmartSearchController {
    // Customizable Area Start
    render() {

        const { classes, t } = this.props;
        if (this.state.loading) {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <Loader loading={this.state.loading} />
                </Grid>
            );
        }
        const cat_lng: any = t("Category")
        const sub_cat_lng: any = t("SubCategory")
        const niq_lng: any = t("Score")
        const fd_Al_lng: any = t("FoodAllergies")
        const fd_Pref_lng: any = t("FoodPreferences")
        const fun_Pref_lng: any = t("FunctionalPreferences")
        const health_Pref_lng: any = t("HealthPreference")
        return (
            <div className={classes.root}>
                <img src={favouriteSmartSearchTopBg} alt="bgImage" style={{ width: "100%" }} />
                <div className={classes.textContainer}>
                    <div className={classes.searchResultText}>
                        <span className={classes.TextUnderline}>{t("FavouriteSmartSearches")}</span>
                    </div>
                    <Button size="small" variant="contained" color="secondary" className={classes.newSmartSrcBtn}
                        data-testid="newSmartSearch"
                        onClick={() => {
                            this.newSearchBtn('SmartSearch');
                            localStorage.removeItem("smartFavID");
                            localStorage.removeItem("favSearchProducts");
                        }}
                    >{t("NewSmartSearch")}</Button>
                </div>
                {this.state.isLogin ?
                    <div className={classes.favouriteSmartSearchContainer}>
                        <Grid container spacing={3}>

                            {this.state.isFavSmartSearchCards &&
                                <Grid item style={{ margin: "0 auto" }}>
                                    <div className={classes.noProductContainer}>
                                        <img src={NoProductFoundImg} alt="bgImage" className={classes.noProductImage} />
                                        <Typography className={classes.noProductTitle}>{t("NoFavouriteSearchesFound")}</Typography>
                                    </div>
                                </Grid>}

                            {!this.state.isFavSmartSearchCards && this.state?.favsearchCards?.data?.map((favCardInfo: any, indexCount: any) => {
                                return (
                                    <Grid item xs={12} sm={6} md={6} style={{ marginTop: '2rem', }} key={favCardInfo?.attributes?.id}>
                                        <div className={`${classes.paper} ${classes.productQualityContainer}`} style={{ border: "1px solid" }} >
                                            <div onClick={() => { this.favSearchResults(favCardInfo); }} style={{ cursor: "pointer", height: "100%", }} data-testid="favSearchResults">
                                                <div className={classes.foodSearchContainer}>
                                                    <Typography className={classes.foodSearchText}>{t("FavouriteSearch")} ({++indexCount})</Typography>
                                                    <div>
                                                        <Typography className={classes.TotalfoodSearchText}>{favCardInfo?.attributes?.product_count}</Typography>
                                                        <Typography className={classes.foodSearchProductText}>{t("product")}</Typography>
                                                    </div>
                                                </div>
                                                <div className={classes.tagList}>

                                                    {/* food type Chip */}
                                                    <Chip
                                                        label={
                                                            <span >
                                                                <span>Food type:&nbsp;</span>
                                                                <span style={{ whiteSpace: "normal" }}>{sanitizeFoodType(JSON.stringify(favCardInfo?.attributes?.food_type))}</span>
                                                                
                                                            </span>
                                                        }
                                                        style={{ display: favCardInfo?.attributes?.food_type.length < 1 ? "none" : "flex", marginLeft: "10px", width: "auto", height: "100%" }}
                                                        classes={{ label: classes.chipLabel, }}
                                                        size="small"
                                                        className={classes.searchResultChips}
                                                        onClick={() => { this.favSearchResults(favCardInfo); }}
                                                        data-testid="favSearchResults"
                                                    />

                                                    {/* Category Chip */}
                                                    <Chip label={
                                                        <span>
                                                            <span>{cat_lng}:&nbsp;</span>
                                                            <span style={{ whiteSpace: "normal" }}>{JSON.stringify(favCardInfo?.attributes?.product_category).replace(/[^a-zA-Z0-9,&]/g, ' ').replace('Packaged Food', "").replace("Packaged Drink", "").replace("Packaged Cheese And Oil", "").replace(configJSON.commaSeperator, ", ").replace(configJSON.colonSeperator, ": ")}</span>
                                                        </span>}

                                                        style={{ marginLeft: 10, display: favCardInfo?.attributes?.product_category === null ? "none" : favCardInfo?.attributes?.product_category === "" ? "none" : "flex", width: "auto", height: "100%" }}
                                                        variant="outlined" className={classes.searchResultChips}
                                                        classes={{ label: classes.chipLabel, }} size="small"
                                                        onClick={() => { this.favSearchResults(favCardInfo); }}
                                                        data-testid="favSearchResults"
                                                    />

                                                    {/* Sub Category Chip */}
                                                    <Chip label={<span>
                                                        <span>{sub_cat_lng}:&nbsp;</span>
                                                        <span style={{ whiteSpace: "normal" }}>{JSON.stringify(favCardInfo?.attributes?.product_sub_category).replace(/[^a-zA-Z0-9:,&]/g, ' ').replace(configJSON.commaSeperator, ", ").replace(configJSON.colonSeperator, ": ")}</span>

                                                    </span>}

                                                        style={{ marginLeft: 10, display: favCardInfo?.attributes?.product_sub_category === null ? "none" : favCardInfo?.attributes?.product_sub_category === "" ? "none" : "flex", width: "auto", height: "100%" }}
                                                        variant="outlined" className={classes.searchResultChips}
                                                        classes={{ label: classes.chipLabel, }} size="small"
                                                        onClick={() => { this.favSearchResults(favCardInfo); }}
                                                        data-testid="favSearchResults"
                                                    />


                                                    {/* NIQ Score */}
                                                    <Chip label={
                                                        <span>
                                                            <span>N<sup>IQ</sup> {niq_lng}:&nbsp;</span>
                                                            {favCardInfo?.attributes?.niq_score?.map((niq: any, cat_index: any) => {
                                                                return (<span style={{ whiteSpace: "normal", }} key={cat_index}>
                                                                    <span>{niq}</span>
                                                                </span>)
                                                            })}
                                                        </span>}

                                                        style={{ marginLeft: 10, display: favCardInfo?.attributes?.niq_score.length < 1 ? "none" : "flex", width: "auto", height: "100%", alignItems: "center" }}
                                                        variant="outlined" className={classes.searchResultChips}
                                                        classes={{ label: classes.chipLabel, }} size="small"
                                                        onClick={() => { this.favSearchResults(favCardInfo); }}
                                                        data-testid="favSearchResults"
                                                    />

                                                    {/* Food Allergies */}
                                                    <Chip
                                                        label={
                                                            <span>
                                                                <span>{fd_Al_lng}:&nbsp;</span>
                                                                <span style={{ whiteSpace: "normal" }}>{JSON.stringify(favCardInfo?.attributes?.food_allergies).replace(/[^a-zA-Z0-9:,]/g, ' ').replace(configJSON.commaSeperator, ", ")}</span>
                                                            </span>
                                                        }
                                                        style={{ display: favCardInfo?.attributes?.food_allergies.length < 1 ? "none" : "flex", marginLeft: "10px", width: "auto", height: "100%" }}
                                                        classes={{ label: classes.chipLabel, }}
                                                        size="small"
                                                        className={classes.searchResultChips}
                                                        onClick={() => { this.favSearchResults(favCardInfo); }}
                                                        data-testid="favSearchResults"
                                                    />

                                                    {/* Food Preference */}
                                                    <Chip
                                                        label={
                                                            <span >
                                                                <span>{fd_Pref_lng}:&nbsp;</span>
                                                                <span style={{ whiteSpace: "normal" }}>{JSON.stringify(favCardInfo?.attributes?.food_preference).replace(/[^a-zA-Z0-9:,]/g, ' ').replace(configJSON.commaSeperator, ", ")}</span>
                                                            </span>
                                                        }
                                                        style={{ display: favCardInfo?.attributes?.food_preference.length < 1 ? "none" : "flex", marginLeft: "10px", width: "auto", height: "100%" }}
                                                        classes={{ label: classes.chipLabel, }}
                                                        size="small"
                                                        className={classes.searchResultChips}
                                                        onClick={() => { this.favSearchResults(favCardInfo); }}
                                                        data-testid="favSearchResults"
                                                    />

                                                    {/* Functional Preference Chip */}
                                                    <Chip
                                                        label={<span>
                                                            <span>{fun_Pref_lng}:&nbsp;</span>
                                                            <span style={{ whiteSpace: "normal" }}>{JSON.stringify(favCardInfo?.attributes?.functional_preference).replace(/[^a-zA-Z0-9:,]/g, ' ').replace(configJSON.commaSeperator, ", ").replace(configJSON.colonSeperator, ": ")}</span>

                                                        </span>}

                                                        style={{ marginLeft: 10, display: favCardInfo?.attributes?.functional_preference === null ? "none" : favCardInfo?.attributes?.functional_preference === "" ? "none" : "flex", width: "auto", height: "100%" }}
                                                        variant="outlined" className={classes.searchResultChips}
                                                        classes={{ label: classes.chipLabel, }} size="small"
                                                        onClick={() => { this.favSearchResults(favCardInfo); }}
                                                        data-testid="favSearchResults"
                                                    />

                                                    {/* Health Preference */}
                                                    <Chip label={
                                                        <span>
                                                            <span>{health_Pref_lng}:&nbsp;</span>
                                                            <span style={{ whiteSpace: "normal", }}>{!favCardInfo?.attributes?.health_preference ? "" : favCardInfo?.attributes?.health_preference.replace(configJSON.emptySeperator, "")}</span>
                                                        </span>}

                                                        style={{ marginLeft: 10, display: !favCardInfo?.attributes?.health_preference ? "none" : "flex", width: "auto", height: "100%", alignItems: "center" }}
                                                        variant="outlined" className={classes.searchResultChips}
                                                        classes={{ label: classes.chipLabel, }} size="small"
                                                        onClick={() => { this.favSearchResults(favCardInfo); }}
                                                        data-testid="favSearchResults"
                                                    />
                                                </div>
                                            </div>

                                            {/* Delete Favourite Search */}
                                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", }}>
                                                <IconButton size='small' onClick={(e: any) => { this.handleDeleteFavSearch(favCardInfo?.id); }} data-testid="deleteFavSearch">
                                                    <DeleteIcon style={{ color: "#FF3939" }} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })}
                            {/* Progress Modal while deleting favourite search */}
                            {/* @ts-ignore */}
                            <Modal
                                open={this.state.deletingFavSearch}
                                aria-labelledby="Delete-Modal"
                                aria-describedby="Delete-Modal-enables-while-fetching-delete-api-to-delete-favourite-search"
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", zIndex: 9999 }}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 1500,
                                }}
                            >
                                {/* <Fade in={this.state.deletingFavSearch}> */}
                                    {/* @ts-ignore */}
                                    {/* <div style={{ position: "relative", display: "inline-flex" }}>
                                        <CircularProgress size={75} style={{ color: "#12125c", }} thickness={2} />

                                        <div style={{ top: 0, left: 0, bottom: 0, right: 0, position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", }}>
                                            <DeleteIcon style={{ color: "#FF3939" }} fontSize="large" />
                                        </div>
                                    </div> */}

                                {/* </Fade> */}

                            </Modal>

                            <Dialog
                                open={this.state.deleteDialog}
                                style={{ zIndex: 9999 }}
                                aria-labelledby="form-dialog-title"
                                BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
                            >
                                <div className={classes.modelContent}>
                                    {/* <MuiDialogTitle disableTypography> */}

                                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => { this.setState({ deleteDialog: false }) }} data-testid="cancelDeleteFavSearch">
                                        <CloseIcon style={{ fontSize: '13px' }} />
                                    </IconButton>

                                    {/* </MuiDialogTitle> */}
                                    <DialogTitle id="form-dialog-title">
                                        <Typography className={classes.notificationText}>Are you sure! want to delete ?</Typography>
                                    </DialogTitle>

                                    <DialogActions>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-around"
                                            alignItems="center"
                                        >
                                            <Button variant="outlined" onClick={() => { this.setState({ deleteDialog: false }) }} className={classes.cancelDilogBoxBtn} data-testid="cancelDeleteFavSearch">
                                                Cancel
                                            </Button>
                                            <Button variant="contained" className={classes.deleteDilogBoxBtn} onClick={this.confirmDeleteFavSearch} data-testid="confirmDeleteFavSearch">
                                                Delete
                                            </Button>
                                        </Grid>
                                    </DialogActions>
                                </div>
                            </Dialog>

                        </Grid>
                    </div>
                    :
                    <Grid item style={{ margin: "0 auto" }}>

                        <div className={classes.noProductContainer} style={{ paddingTop: 100, paddingBottom: 100 }}>
                            <Typography className={classes.noProductTitle} style={{ color: "#455A64" }}>{t("loginText")}</Typography>
                        </div>
                    </Grid>}

                {/* @ts-ignore */}
                <Dialog
                    open={this.state.loginNotifiModalOpen}
                    BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
                >
                    <div className={classes.modelContent}>

                        <DialogTitle id="form-dialog-title">
                            <Typography className={classes.notificationText}>{t("loginToUnlock")}</Typography>
                        </DialogTitle>

                        <DialogActions>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                            >
                                <Button variant="outlined" onClick={this.handleLoginNotifiModalClose} className={classes.cancelDilogBoxBtn}>
                                    {t("cancel")}
                                </Button>
                                <Button variant="contained" className={classes.deleteDilogBoxBtn} onClick={() => this.navigationBtnFnc('UserSignUpScreenWeb')} data-testid='loginButton'>
                                    {t("login")}
                                </Button>
                            </Grid>
                        </DialogActions>
                    </div>
                </Dialog>

            </div >
        );
    }
}
// Customizable Area End
export default withStyles(styles, { withTheme: true })(withTranslation('smartSearchLng')(FavouriteSmartSearchWeb));