import React, { useEffect, useState } from "react";
// import { View, Button, StyleSheet } from "react-native";

import { withRouter } from "react-router-dom";
//Customise area start
import {
  AppBar,
  Button,
  Toolbar,
  Box,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Avatar,
  Typography,
} from "@material-ui/core";
import NiqLogo from "../assets/Asset 2 1.svg";
import whatsyourSvg from "../assets/WhatsYour.svg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import myprofile from "../assets/myprofile.svg";
import healthpoints from "../assets/healthpoints.svg";
import bmicalculator from "../assets/bmicalculator.svg";
import NutritionGapCal from "../assets/NutriGapCal.svg";
import HealthyFoodBasket from "../assets/HealthyFB.svg";
import Preferences from "../assets/Preferences.svg";
import Myorder from "../assets/Myorder.svg";
import submitAproduct from "../assets/SubmitAPro.svg";
import Logout from "../assets/Logout.svg";
import CustomSearch from "./CustomSearch"
import { useTranslation } from "react-i18next";
import { useTheme } from "../../blocks/recommendationengine/src/context/FavouriteProductContext";

const useStyles = makeStyles((theme) =>
  createStyles({
    containerStyle: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: 50,
      marginRight: 50,
      [theme.breakpoints.down("xl")]: {
        marginLeft: 40,
        marginRight: 40,
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: 30,
        marginRight: 30,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: 20,
        marginRight: 20,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 10,
        marginRight: 10,
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    MenuStyle: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    search: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: "#fff",
      borderRadius: "59px",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      paddingRight: "1em",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
      gap: "10px",
    },

    buttonContainerStyle: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: 10,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    buttonStyle: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "27px",
      textTransform: "capitalize",
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },

    cartFavStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginRight: 10,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    menuButtonCartFav: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        alignItems: "center"
      },
    },

    menuButtonSignUp: {
      display: "none",
      [theme.breakpoints.down(720)]: {
        display: "flex",
        alignItems: "center"
      },
    },

    MenuItemStyle: {
      color: "#38006D",
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "13px",
    },

    SignUpButtonContainerStyle: {
      display: "flex",
      [theme.breakpoints.down(720)]: {
        display: "none",
      },
    },
    UserMenuContainerStyle: {
      display: "flex",
      [theme.breakpoints.down(720)]: {
        display: "none",
      },
    },

    SignUpButtonStyle: {
      color: "#38006D",
      background: "#ffffff",
      borderRadius: "5px",
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "20px",
    },

    badgeStyle: {
      "& > *": {
        color: "#38006D",
        backgroundColor: "#FED600;",
      },
    },
    small: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    menuProfilesmall: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    UserMenuItemStyle: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "24px",
    },
    userName: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "16px",
      color: "#FFFFFF",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "72px",
    },

    logoInSearchHide: {
      display: "flex",
      cursor: "pointer",
      flexGrow: "inherit",
      justifyContent: 'flex-start',
      [theme.breakpoints.down(720)]: {
        flexGrow: 1,
        justifyContent: 'center',
      },
    },
    logoInSearchNotHide: {
      display: "flex",
      cursor: "pointer",
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },


    },
    siteLogositeLogoText: {
      [theme.breakpoints.down("sm")]: {
        width: '4px'
      },
    },
    siteLogo: {
      [theme.breakpoints.down("sm")]: {
        width: '50px'
      },
    },

  })
);

//Customise area stop

const TopNav = ({ history }) => {
  //Customise area start
  const classes = useStyles();
  const { allFavProducts } = useTheme();
  const [t] = useTranslation(["translation", "TopNavLng"]);
  const [profileMenu, setProfileMenu] = React.useState(null);
  const [userProfileMenu, setUserProfileMenu] = React.useState(null);
 
  const favCountHandler = () => {
 
    if(localStorage.getItem('token') && localStorage.getItem('token') != '' &&  localStorage.getItem('token') != null && allFavProducts?.length){
     return allFavProducts?.length
    }
    else {
        return 0;    
      }
    }
 
  const handelLogoutBtn = () => {          
    setUserProfileMenu(null);
    localStorage.clear();  
    history.push("/")
  };
 
  //Customise area stop
  return (
    // Customise area start
    <AppBar
      style={{
        background:
          "linear-gradient(169.52deg, #38006F 55.17%, #FF3939 111.06%)",
        display: history?.location?.pathname == "/signup" ? "none" : false,
        zIndex: 9999,
      }}>
      <Toolbar disableGutters>
        <Box className={classes.containerStyle}>
          <Box className={classes.MenuStyle}>
            <IconButton
              aria-haspopup="true"
              color="inherit"
              aria-controls="profile-menu"
              onClick={(e) => setProfileMenu(e.currentTarget)}
              size={"medium"}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="profile-menu"
              open={Boolean(profileMenu)}
              anchorEl={profileMenu}
              elevation={0}
              style={{ marginTop: 10 }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={() => setProfileMenu(null)}
              disableAutoFocusItem
            >
              <MenuItem
                onClick={() => {
                  setProfileMenu(null);
                  history.push("/");
                }}
                className={classes.MenuItemStyle}
                style={{
                  color:
                    history?.location?.pathname == "/" ? "#FED600" : "#38006D",
                }}
              >
                {t("TopNavLng:Home")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/UserProfilePageWeb")
                  setProfileMenu(null);
                }}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}
              >
                {t("TopNavLng:MyProfile")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setProfileMenu(null)
                  history.push("/FavouriteSmartSearchWeb");
                }}
                className={classes.MenuItemStyle}
              >
 
                {t("TopNavLng:SmartSearch")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setProfileMenu(null)
                }}
                className={classes.MenuItemStyle}
              >
                {t("TopNavLng:KnowYourHealth")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setProfileMenu(null)
                }}
                className={classes.MenuItemStyle}
              >
                {t("TopNavLng:GoodMonk")}
              </MenuItem>
              <MenuItem
                onClick={() => setProfileMenu(null)}
                className={classes.MenuItemStyle}
              >
                {t("TopNavLng:HealthBlog")}
              </MenuItem>
              {/* <MenuItem
                onClick={() => setProfileMenu(null)}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}
              >
                {t("TopNavLng:Cart")}
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  history.push("/favoriteProducts");
                  setProfileMenu(null)
                }}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {t("TopNavLng:Favorite")}
                <Badge badgeContent={favCountHandler()} max={99} className={classes.badgeStyle} />
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  history.push("/BmiCalculatorPageWeb")
                  setProfileMenu(null);
                }}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}
              >
                {t("TopNavLng:BMICalculator")}
              </MenuItem> */}
              {/* <MenuItem
                onClick={() => {
                  history.push("/HealthPointsWebPage")
                  setProfileMenu(null);
                }}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}
              >
                {t("TopNavLng:HealthPoints")}
              </MenuItem> */}
              {/* <MenuItem
                onClick={() => {
                  history.push("/ProductReportedWebPage")
                  setProfileMenu(null);
                }}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}
              >
                {t("TopNavLng:ReportedProducts")}
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  history.push("/HealthyFoodBasket")
                  setProfileMenu(null);
                }}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}
              >
                {t("TopNavLng:FoodBasket")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/yourproducts")
                  setProfileMenu(null);
                }}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}
              >
                {t("TopNavLng:SubmitAndReportProduct")}
              </MenuItem>
              {localStorage.getItem('token') && localStorage.getItem('token') != '' && localStorage.getItem('token') != null ? (
                <MenuItem
                  onClick={() => {
                    handelLogoutBtn();
                    setProfileMenu(null);
                  }}
                  className={[classes.menuButtonSignUp, classes.MenuItemStyle]}
                >
                  {t("TopNavLng:Logout")}
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    history.push("/signup");
                    setProfileMenu(null);
                  }}
                  className={[classes.menuButtonSignUp, classes.MenuItemStyle]}
                  style={{
                    color:
                      history?.location?.pathname == "/signup"
                        ? "#FED600"
                        : "#38006D",
                  }}
                >
                  {t("TopNavLng:LoginSignup")}
                </MenuItem>
              )}
            </Menu>
          </Box>
 
          <Box
            onClick={() => { history.push("/"); }}
            className={`${history?.location?.pathname === "/landingPage" ? classes.logoInSearchHide : classes.logoInSearchNotHide}`}
          >
            <img src={whatsyourSvg} alt="WhatsYourStp" className={classes.siteLogositeLogoText} />
            <img src={NiqLogo} alt="NiqLogo" className={classes.siteLogo} />
          </Box>
          {/* Search */}
          <Box style={{
            display: history.location.pathname === "/landingPage" ? "none" : "block"
          }}>
            <CustomSearch data={"TopNav"} />
          </Box>
 
          {/* Search */}
 
          <Box className={classes.buttonContainerStyle}>
            <Button
              className={classes.buttonStyle}
              style={{
                color: history?.location?.pathname === "/" ? "#FED600" : "#FFFFFF",
              }}
              onClick={() => { history.push("/"); }}
            >
              {t("TopNavLng:Home")}
            </Button>
 
            <Button
              className={classes.buttonStyle}
              style={{
                color: history?.location?.pathname === "/FavouriteSmartSearchWeb" ? "#FFBE00" : "#FFFFFF",
 
              }}
              onClick={() => { history.push("/FavouriteSmartSearchWeb"); }}
            >
              {t("TopNavLng:SmartSearch")}
            </Button>
 
            <Button
              className={classes.buttonStyle}
              style={{
                // color: history?.location?.pathname === "/" ? "#FED600": "#FFFFFF",
                color: "#FFFFFF",
              }}
            >
              {t("TopNavLng:KnowYourHealth")}
              {/* {t("TopNavLng:HealthBlog")} */}
            </Button>
 
            <Button
              className={classes.buttonStyle}
              style={{
                // color: history?.location?.pathname === "/" ? "#FED600": "#FFFFFF",
                color: "#FFFFFF",
              }}
            >
              {t("TopNavLng:GoodMonk")}
 
            </Button>
 
            <Button
              className={classes.buttonStyle}
              style={{
                // color: history?.location?.pathname === "/" ? "#FED600": "#FFFFFF",
                color: "#FFFFFF",
              }}
            >
              {t("TopNavLng:HealthBlog")}
            </Button>
          </Box>
 
          <Box className={classes.cartFavStyle}>
            <IconButton
              size="medium"
              aria-label="show 1 item in cart"
              color="inherit"
            >
              <Badge badgeContent={0} max={9} className={classes.badgeStyle}>
                <ShoppingCartIcon
                  style={{ background: "none", color: "#fff" }}
                />
              </Badge>
            </IconButton>
 
            <IconButton
              size="medium"
              aria-label="show 1 new Favorite"
              color="inherit"
              onClick={() => history.push("/favoriteProducts")}
            >
              <Badge badgeContent={favCountHandler()} max={9} className={classes.badgeStyle}>
                <FavoriteBorderIcon
                  style={{ background: "none", color: "#fff" }}
                />
              </Badge>
            </IconButton>
          </Box>
 
          {localStorage.getItem('token') && localStorage.getItem('token') != '' && localStorage.getItem('token') != null ? (
            <Box className={classes.UserMenuContainerStyle}>
              {localStorage.getItem("user") ? (
                <Avatar
                  alt={JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                  src={JSON.parse(localStorage.getItem("user")).image}
                  className={classes.small} />
              ) : (
                <Avatar
                  alt={JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                  src={"/static/images/avatar/1.jpg"}
                  className={classes.small}
                />
              )}
 
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: 5,
                }}
              >
                <Typography className={classes.userName}>
                  {JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => setUserProfileMenu(e.currentTarget)}
                >
                  <Typography
                    style={{
                      fontFamily: `'Poppins', sans-serif`,
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "9px",
                      lineHeight: "14px",
                      color: "#FED600",
                    }}
                  >
 
                    {t("TopNavLng:MyAccount")}
                  </Typography>
                  <ArrowDropDownIcon />
                </Box>
              </Box>
 
              <Box
                className={classes.MenuStyle}
                style={{ position: "relative" }}
              >
                <Menu
                  id="profile-menu"
                  open={Boolean(userProfileMenu)}
                  anchorEl={userProfileMenu}
                  elevation={0}
                  style={{ marginTop: 10 }}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={() => setUserProfileMenu(null)}
                  disableAutoFocusItem
                >
                  <MenuItem className={classes.UserMenuItemStyle}>
                    {localStorage.getItem("user") ? (
                      <Avatar
                        alt={JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                        src={JSON.parse(localStorage.getItem("user")).image}
                        className={classes.menuProfilesmall}
                      />
                    ) : (
                      <Avatar
                        alt={JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                        src="/static/images/avatar/1.jpg"
                        className={classes.menuProfilesmall}
                      />
                    )}
 
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: 5,
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: `'Poppins', sans-serif`,
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "11px",
                          lineHeight: "16px",
                          color: "#455A64",
                        }}
                      >
                        {JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                      </Typography>
 
                      <Typography
                        style={{
                          fontFamily: `'Poppins', sans-serif`,
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "9px",
                          lineHeight: "14px",
                          color: "#FED600",
                        }}
                      >
                        {t("TopNavLng:MyAccount")}
                      </Typography>
                    </Box>
                  </MenuItem>
 
                  <MenuItem
                    onClick={() => {
                      setUserProfileMenu(null);
                      history.push("/UserProfilePageWeb")
                    }}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={myprofile}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    {t("TopNavLng:MyProfile")}
                  </MenuItem>
 
                  {/* <MenuItem
                    onClick={() => {
                      setUserProfileMenu(null);
                      history.push("/HealthPointsWebPage")
                    }}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={healthpoints}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    {t("TopNavLng:HealthPoints")}
                  </MenuItem> */}
 
                  {/* <MenuItem
                    onClick={() => {
                      setUserProfileMenu(null);
                      history.push("/BmiCalculatorPageWeb")
                    }}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={bmicalculator}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    {t("TopNavLng:BMICalculator")}
                  </MenuItem> */}
 
                  {/* <MenuItem
                    onClick={() => {
                      setUserProfileMenu(null);
                      history.push("/NutritionGapCalculatorWeb")
                    }}
                    style={{ lineHeight: 1.5 }}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={NutritionGapCal}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    {t("TopNavLng:NutritionGap")}
                    <br />
                    {t("TopNavLng:Calculator")}
                  </MenuItem> */}
 
                  <MenuItem
                    onClick={() => {
                      setUserProfileMenu(null);
                      history.push("/HealthyFoodBasket")
                    }}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={HealthyFoodBasket}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    {t("TopNavLng:FoodBasket")}
                  </MenuItem>
 
                  <MenuItem
                    onClick={() => setUserProfileMenu(null)}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={Preferences}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    {t("TopNavLng:Preferences")}
                  </MenuItem>
 
                  {/* <MenuItem
                    onClick={() => setUserProfileMenu(null)}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={Myorder}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    {t("TopNavLng:MyOrder")} smart search
                  </MenuItem> */}
 
                  <MenuItem
                    onClick={() => { setUserProfileMenu(null); history.push("/yourproducts") }}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={submitAproduct}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    {t("TopNavLng:SubmitAndReportProduct")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => { setUserProfileMenu(null); history.push("/SmartSearch") }}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={submitAproduct}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    {t("TopNavLng:SmartSearch")}
                  </MenuItem>
 
                  <MenuItem
                    onClick={() => { setUserProfileMenu(null); handelLogoutBtn(); }}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={Logout}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    {t("TopNavLng:Logout")}
                  </MenuItem>
                </Menu>
              </Box>
 
            </Box>
          ) : (
            <Box className={classes.SignUpButtonContainerStyle}>
              <Button
                className={classes.SignUpButtonStyle}
                variant="contained"
                onClick={() => history.push("/signup")}
              >
                {t("TopNavLng:LoginSignup")}
              </Button>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
    //Customise area stop
 
    // <View style={styles.main}>
    //   <Button title="Signup" onPress={() => history.push("/SocialMediaAccountRegistrationScreen")} />
    // </View>
  );
};

// const styles = StyleSheet.create({
//   main: {
//     flexDirection: "row",
//     justifyContent: "space-evenly",
//     backgroundColor: "#cccccc"
//   }
// });

export default withRouter(TopNav);