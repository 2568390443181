import React from 'react';
import UserProfileWebController from './UserProfileWebController';
import { Typography, Grid, Button } from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../components/src/SideNavbarWeb';
import { sideBarBanner } from '../../recommendationengine/src/assets'
import { healthPointArrow, newTopBanner } from './assets'
import CustomDeleteAlartDailogBox from '../../../components/src/CustomDeleteAlartDailogBox';
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BmiNewProfileMemberLists } from './bmi_calculator/BmiNewProfileMemberLists';
import { LoadingErrorNotificationText } from '../../../components/src/LoadingErrorNotificationText';



class UserProfilePageWeb extends UserProfileWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
            userProfileInfo,
            newMemberListLoading
        } = this.state;
        const { classes, t } = this.props;

        return (
            <>
                <div className={classes.root}>
                    <Grid container>
                        <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                            <SideNavbarWeb />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10}>
                            <img src={newTopBanner} alt="bgImage" style={{ width: "100%" }} className={classes.profileTopBanner} />
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>{t("My_Profile")}</Typography>
                            </div>

                            {
                                loading ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> : (
                                    <div className={classes.userProfileContainer}>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4} md={4} className={classes.containerTopMergin} >
                                                <div className={`${classes.paper} ${classes.healthPoitGrid}`} >
                                                    <Typography className={classes.healthPoint}>4589</Typography>
                                                    <Typography className={classes.healthPointGainText}>{t("Your_Health_Points")}</Typography>
                                                    <img src={healthPointArrow} alt="" width={30} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={2} md={2} className={classes.containerTopMergin} >
                                                <div className={`${classes.paper} ${classes.totalNumberGrid}`} style={{ border: '2px solid #41479B' }}>
                                                    <Typography className={classes.totalNumberText} style={{ color: '#41479B' }}>{userProfileInfo.length === 0 ? '--' : userProfileInfo?.attributes?.added_member_count}</Typography>
                                                    <Typography className={classes.profileDashboardText}>{t("Total")} <br /> {t("Member")}</Typography>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} sm={2} md={2} className={classes.containerTopMergin} >
                                                <div className={`${classes.paper} ${classes.totalNumberGrid}`} style={{ border: '2px solid #FB951A' }} >
                                                    <Typography className={classes.totalNumberText} style={{ color: '#FB951A' }}>{userProfileInfo.length === 0 ? '--' : userProfileInfo?.attributes?.favourite_products_count}</Typography>
                                                    <Typography className={classes.profileDashboardText}>{t("Favourites")} <br /> {t("Products")}</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={2} md={2} className={classes.containerTopMergin} >
                                                <div className={`${classes.paper} ${classes.totalNumberGrid}`} style={{ border: '2px solid #FF5050' }} >
                                                    <Typography className={classes.totalNumberText} style={{ color: '#FF5050' }}>{userProfileInfo.length === 0 ? '--' : userProfileInfo?.attributes?.food_basket_count}</Typography>
                                                    <Typography className={classes.profileDashboardText}>{t("Healthy")} <br /> {t("Food")} <br /> {t("Basket")}</Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={2} md={2} className={classes.containerTopMergin} >
                                                <div className={`${classes.paper} ${classes.totalNumberGrid}`} style={{ border: '2px solid #008D59' }} >
                                                    <Typography className={classes.totalNumberText} style={{ color: '#008D59' }}>{userProfileInfo.length === 0 ? '--' : userProfileInfo?.attributes?.fav_smart_search_count}</Typography>
                                                    <Typography className={classes.profileDashboardText}>{t("Favorites")} <br /> {t("Smart")} <br /> {t("Search")}</Typography>
                                                </div>
                                            </Grid>
                                        </Grid>


                                        {/* new member list {rf} */}

                                        {newMemberListLoading ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> : (
                                            <>
                                                {
                                                    this.state.errorReponse ? <LoadingErrorNotificationText /> : <BmiNewProfileMemberLists profileLists={this.state.newMemberList} handleDeleteModalOpen={this.handleDeleteModalOpen} navigationProps={this.props.navigation} />
                                                }

                                            </>
                                        )}

                                        {/* add bmiProfile btn {rf} */}
                                        <div style={{ textAlign: 'center' }}>

                                            <Button
                                                data-testid='AddNewMemberPageWeb'
                                                onClick={
                                                    () => this.navigationBtnFnc('AddNewMemberPageWeb')}
                                                className={classes.productButton} >
                                                {t("Add_a_Member")} +
                                            </Button>
                                        </div>
                                    </div>
                                )
                            }

                        </Grid >
                    </Grid >

                    <CustomDeleteAlartDailogBox isOpen={this.state.openDeleteModalBox} cancleFunction={this.handleModalClose} deleteFunction={this.deleteProfileMember} deleteId='' />
                </div >
                {/* } */}
            </>
        );

    }
}
// Customizable Area End

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        profileTopBanner: {
            [theme.breakpoints.only("sm")]: {
                marginTop: "15px"
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: "7px"
            },
        },

        // css for headingTextContainer
        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            // marginLeft: 25,
            // marginRight: 25,
            alignItems: 'center',
            margin: '1.5rem 25px 1.5rem 25px',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: 5,
                marginRight: 5,
            },
        },
        pageHeadingText: {
            color: '#37006E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "33px",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
        },

        // css for userProfileContainer
        userProfileContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },
        linearGradientBorderColorContainer: {
            width: '100%',
            cursor: "pointer",
            margin: '1.5rem 0',
            border: "double 2px transparent",
            borderRadius: '10px',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(80deg,#38006F,#FF5050)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
        },
        deleteButton: {

            width: '50px',
            height: '50px',
            border: "double 1px transparent",
            borderRadius: '50%',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
        },


        healthPoint: {
            fontSize: '50px',
            textAlign: "center",
            color: '#FFFFFF',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            [theme.breakpoints.only('sm')]: {
                fontSize: '28px',
            }
        },
        healthPoitGrid: {
            backgroundColor: '#38006D',
            borderRadius: '10px',
            color: '#ffff',
            height: '180px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
        },

        healthPointGainText: {
            textAlign: "center",
            color: '#FFFFFF',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '2.5rem',
            marginBottom: '1rem',
            [theme.breakpoints.only('sm')]: {
                fontSize: '17px',
            }
        },
        totalNumberGrid: {
            borderRadius: '10px',
            height: '180px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
        },
        totalNumberText: {
            textAlign: "center",
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '45px',
            height: '50%',
            [theme.breakpoints.only('sm')]: {
                fontSize: '17px',
            }
        },
        profileDashboardText: {
            textAlign: "center",
            color: '#263238',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '1.5rem',
            lineHeight: '2rem',
            height: '50%',
            [theme.breakpoints.only('sm')]: {
                fontSize: '0.9rem',
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '1.2rem',
            },
        },


        familyMemberName: {
            color: '#2E445C',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '24px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '15px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '17px',
            },
        },
        familyMemberNameGender: {
            color: '#A8A8A8',
            textTransform: 'capitalize'
        },
        familyMemberSubText: {
            color: '#F89B86',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '18px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '11px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '15px',
            },
        },
        bmiNumberText: {
            color: '#41479B',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '30px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '15px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '17px',
            },
        },
        bmiText: {
            color: '#ADADAD',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '15px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '11px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '11px',
            },
        },
        healthProductNiqScore: {
            color: '#757D8A',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '20px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '13px',
            },
        },


        childTextStyle: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '18px'
        },
        productRating: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 3rem',
            [theme.breakpoints.only('xs')]: {
                margin: '0 2rem',
            }
        },
        healthFoodImage: {
            width: '130px',
            height: '130px',
            [theme.breakpoints.only("xs")]: {
                width: '70px',
                height: '70px',
            }
        },
        deleteIconBtn: {
            color: '#FF3939', border: '1px solid #FF784B',
            width: '40px',
            height: '40px',
            [theme.breakpoints.only("xs")]: {
                width: '30px',
                height: '30px',
            }
        },
        deleteIconFontSize: {
            [theme.breakpoints.only("xs")]: {
                fontSize: '14px'
            }
        },

        deleteAndCompareContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        containerTopMergin: {

            marginTop: 0,
            [theme.breakpoints.down("sm")]: {
                marginTop: '1.5rem'
            },
        },
        productButton: {
            fontFamily: `'Montserrat', sans-serif`,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '22px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            background: '#38006D',
            borderRadius: "44px",
            padding: '10px 80px',
            '&:hover': {
                background: '#12125c',
                border: 'none',
            },
            [theme.breakpoints.down("md")]: {
                padding: '12px 60px',
                fontSize: '15px',
            },
            [theme.breakpoints.down("sm")]: {
                padding: '8px 50px',
                fontSize: '12px',
            },
        },
        scrollingProfileContainer: {
            maxHeight: 640,
            overflowY: "scroll",
            overflow: "hidden",
            '&::-webkit-scrollbar': {
                width: '5px',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,0.00)',
            }
        },

    });

export default withStyles(styles, { withTheme: true })(
    withTranslation('UserProfilePageWebLang')(UserProfilePageWeb)
);