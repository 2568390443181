export const heroBgImage = require('../assets/hero_bg_image.png');
export const heroTopImage = require('../assets/hero_top_image.png');
export const aboutImage = require('../assets/about_image.png');
export const extensiveDataImage = require('../assets/extensive_data_image.png');
export const footerLogoImage = require('../assets/footer_logo_image.png');
export const footerPhoneStoreImage = require('../assets/footer_phone_store_image.png');
export const globalGuidelinesImage = require('../assets/global_guidelines_image.png');
export const  nutritiveValueImage = require('../assets/nutritive_value_image.png');
export const  nutritiveValueBgImage = require('../assets/nutritive_value_Bg_image.png');
export const  productAnalysisImage = require('../assets/product_analysis_image.png');
export const  smartSearchImage = require('../assets/smart_search_image.png');
export const lineNiq =  require('../assets/LineNiq.svg');
export const linePA =  require('../assets/LinePA.svg');
export const lineEDT =  require('../assets/LineEDT.svg');
export const lineSS =  require('../assets/LineSS.png');

export const QRicon =  require('../assets/QRScanIcon.svg');
export const VoiceSearch =  require('../assets/VoiceSearchIcon.svg');
export const LandingPageBgImage =  require('../assets/Rectangle.png');