import React from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    userHeightWeightValueText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "33px",
        color: "#ffff",
        [theme.breakpoints.down("sm")]: {
            fontSize: '23px',
        },
    },
    userHeightWeightText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "15px",
        color: "#FB951A",
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px',
        },
    },
    unitText: {
        fontSize: "15px",
        [theme.breakpoints.down("sm")]: {
            fontSize: '12px',
        },
    },
}));
const BmiMeasurementInfo = ({ BmiMeasurementLable, BmiMeasurementValue, BmiMeasurementUnit }: any) => {
    const classes = useStyles();
    return (
        <div>
            <Typography className={classes.userHeightWeightValueText}>{BmiMeasurementValue}<sub className={classes.unitText}>{BmiMeasurementUnit}</sub> </Typography>
            <Typography className={classes.userHeightWeightText}>{BmiMeasurementLable}</Typography>
        </div>

    )
}

export default BmiMeasurementInfo