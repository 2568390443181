import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  t?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading:boolean;
  expanded: any;
  aboutUs:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getAboutUsApiCallID:any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start      
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage),        
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      expanded: false,
      loading:false,
      aboutUs:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAboutUsApiCallID &&
      this.getAboutUsApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );      
      this.setState({aboutUs:responseJson,loading: false});              
      }   
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();    
    if (this.props?.navigation?.history?.location?.pathname === "/aboutUs") {  
    this.getAboutUs()
  }
  }
  handleChange = (isExpanded:boolean, panel: string) => {
    this.setState({expanded: isExpanded ? panel : false})
  }

  getAboutUs = () => {     
    this.setState({ loading: true })
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': 'application/json',
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAboutUsApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.aboutUs);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  // Customizable Area End
  
}
