import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles, } from "@material-ui/core/styles";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  isRegistration: boolean;
  gridItemNumber: number;
  mobileNumberInputPageEnable: boolean;
  otpInputPageEnable: boolean;
  describeInputPageEnable: boolean;
  guestUser: boolean;
  gotErrorResponse: boolean;
  uToken: any;
  inValidOTP: any;
  isInValidOTP: boolean;
  expireOTP: any;
  isExpireOTP: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserSignUpScreenWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSentOtpCallmessageId: string = '';
  apiOtpVerificationmessageId: string = '';
  resendOtpCallmessageId: string = '';
  facebookAuthMessageId: string = '';
  googleAuthMessageId: string = '';
  userProfileUpdateMessageId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      loading: false,
      isRegistration: false,
      gridItemNumber: 1,
      mobileNumberInputPageEnable: true,
      otpInputPageEnable: false,
      describeInputPageEnable: false,
      guestUser: false,
      gotErrorResponse: false,
      uToken: '',
      inValidOTP: '',
      isInValidOTP: false,
      expireOTP: '',
      isExpireOTP: false,
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallIds = {
        [this.apiSentOtpCallmessageId]: this.otpSendPostAPIResponse,
        [this.apiOtpVerificationmessageId]: this.otpVerificationAPIResponse,
        [this.resendOtpCallmessageId]: this.resendOtpAPIResponse,
        [this.facebookAuthMessageId]: this.faceBookAuthAPIResponse,
        [this.googleAuthMessageId]: this.googleAuthAPIResponse,
        [this.userProfileUpdateMessageId]: this.userProfileUpdateAPIResponse,
      };

      if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](responseJson, errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const haveToken = localStorage.getItem('token')
    if (haveToken) {
      this.navigationBtnFnc('Home')
    } else {
      if (localStorage.getItem('mobileNumberInputPageEnable') && localStorage.getItem('otpInputPageEnable') && localStorage.getItem('describeInputPageEnable')) {
        this.setState(
          {
            mobileNumberInputPageEnable: localStorage.getItem('mobileNumberInputPageEnable') === 'true' ? true : false,
            otpInputPageEnable: localStorage.getItem('otpInputPageEnable') === 'true' ? true : false,
            describeInputPageEnable: localStorage.getItem('describeInputPageEnable') === 'true' ? true : false,
          }
        )
      }
    }
  }


  // <-------------------------- Here API Calling functionalities {rf} -------------------------->
  sentMobileOtpRequest = (phoneNumber: string) => {
    this.startLoading();
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      // token: userToken,
    };

    const httpBody = {
      data: {
        type: 'sms_account',
        attributes: {
          full_phone_number: phoneNumber,
        },
      },
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.apiSentOtpCallmessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSignupEndPointURL // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // <---------- API call for otp verification code {rf} ---------->
  mobileOtpVerification = (otp: string) => {
    this.startLoading();
    const userToken = localStorage.getItem("otpToken");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    const httpBody = {
      data: {
        type: 'sms_account',
        attributes: {
          pin: otp,
        },
      },
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.apiOtpVerificationmessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpVerificationEndPontURL // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // <---------- API call for  Resend OTP request {rf} ---------->

  resentOtpRequest = (phoneNumber: any) => {

    this.startLoading();
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
    };

    const httpBody = {
      data: {
        type: 'email_account',
        attributes: {
          full_phone_number: phoneNumber,
        },
      },
    };

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.resendOtpCallmessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resentOtpRequestEndPontURL // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  socialFacebookAuthResponse = (response: any) => {
    if (response.status === "unknown") {
      this.stopLoading();
    } else {
      this.startLoading();
      const header = {
        'Content-Type': configJSON.applicationJsonContentType,
      };

      const httpBody = {
        data: {
          type: 'social_account',
          attributes: {
            full_name: response?.name,
            email: response?.email,
            unique_auth_id: response?.id,
            image_url: response?.picture?.data?.url
          },
        },
      };

      // rest api call message create
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      // save rest api resuest id
      this.facebookAuthMessageId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountSignupEndPointURL // end point setup
      );

      // set header
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      // sent the body data
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postHttpRequest // api method
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // <---------- API call for socialGoogleAuthResponse {rf} ---------->
  socialGoogleAuthResponse = async (response: any) => {
    this.startLoading();
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
    };

    const httpBody = {
      data: {
        type: 'social_account',
        attributes: {
          full_name: response?.name,
          email: response?.email,
          unique_auth_id: response?.sub,
          image_url: response?.picture
        },
      },
    };

    // Rest API call message creation
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // Save the REST API request id
    this.googleAuthMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSignupEndPointURL // Endpoint setup
    );

    // Set the header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // Set the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest // API method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // <---------- API call for user profile update {rf} ---------->
  userProfileUpdate = (full_name: string, email: string, gender: string) => {
    this.startLoading();
    const userToken = localStorage.getItem("token");
    const header = {
      // 'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    const formData = new FormData();
    formData.append("data[type]", 'social_account');
    formData.append("data[attributes][full_name]", full_name);
    formData.append("data[attributes][email]", email);
    formData.append("data[attributes][gender]", gender);

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.userProfileUpdateMessageId = requestMessage.messageId;
    const uId = localStorage.getItem('id')
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountSignupEndPointURL}/${uId}` // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formData
      // JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchHttpRequest // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // <-------------------------- Here Api responses functionalities {rf} -------------------------->
  userDetailsStoreInLocalStore = (responseJson: any) => {
    const user = {
      id: responseJson?.data?.id,
      type: responseJson?.data?.type,
      name: responseJson?.data?.attributes?.full_name,
      email: responseJson?.data?.attributes?.email,
      image: responseJson?.data?.attributes?.image
    }
    localStorage.clear();
    localStorage.setItem('id', responseJson?.data?.id);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', responseJson?.meta?.token);
  }

  otpSendPostAPIResponse = (responseJson: any, errorReponse: string) => {
    if (errorReponse) {
      return this.navigationBtnFnc('Home')
    }
    if (responseJson?.errors) {
      this.stopLoading();
      this.setState({ gotErrorResponse: true });

    }
    if (responseJson?.status === 500) {
      this.stopLoading();
      this.setState({ gotErrorResponse: true });

    }
    if (responseJson?.meta && responseJson?.data) {
      localStorage.setItem('otpToken', responseJson?.meta?.token)
      localStorage.setItem('id', responseJson?.data?.id)
      this.pageManageInLocalstore(false, true, false);
      this.setState({ gotErrorResponse: false });
      this.stopLoading();
    } else {
      this.setState({ gotErrorResponse: true });
      localStorage.removeItem('inputPhoneNumber')
      this.stopLoading();

    }
  }

  otpVerificationAPIResponse = (responseJson: any, errorReponse: string) => {
    if (errorReponse) {
      return this.navigationBtnFnc('Home')
    }
    if (responseJson?.errors) {
      this.stopLoading();
      if (responseJson?.errors[0]?.pin) {
        this.setState({ inValidOTP: responseJson?.errors[0]?.pin, isInValidOTP: true })
      }
      if (responseJson?.errors[0]?.phone) {
        this.otpExpire(responseJson?.errors[0]?.phone)
      }

    }

    if (responseJson?.data) {
      this.userDetailsStoreInLocalStore(responseJson)
      this.setState({ isInValidOTP: false })
      if (responseJson?.meta?.additional_details) {
        this.pageManageInLocalstore(true, false, false);
        this.stopLoading();
        this.navigationBtnFnc('Home')
      } else {
        if (this.state.guestUser) {
          this.pageManageInLocalstore(true, false, false);
          this.setState({ guestUser: false });
          this.stopLoading();
          this.navigationBtnFnc('Home')
        } else {
          this.pageManageInLocalstore(false, false, true);
          this.stopLoading();
        }
      }

    }
  }

  resendOtpAPIResponse = (responseJson: any, errorReponse: string) => {
    if (errorReponse) {
      this.navigationBtnFnc('Home')
    }
    if (responseJson?.errors) {
      this.stopLoading();
      // alert(responseJson?.errors[0]?.full_phone_number)

    }
    if (responseJson?.status === 500) {
      localStorage.clear();
      this.stopLoading();
      // alert(responseJson?.error)

    }
    if (responseJson?.meta) {
      localStorage.setItem('otpToken', responseJson?.meta?.token)

      this.stopLoading();

    }
  }
  faceBookAuthAPIResponse = (responseJson: any, errorReponse: string) => {
    if (errorReponse) {
      this.navigationBtnFnc('Home')
    }
    if (responseJson?.data && !responseJson?.meta?.message) {

      this.userDetailsStoreInLocalStore(responseJson)
      if (responseJson?.meta?.additional_details) {
        this.pageManageInLocalstore(true, false, false);
        this.stopLoading();
        this.navigationBtnFnc('Home')
      } else {
        this.pageManageInLocalstore(false, false, true);
        this.stopLoading();
      }

    }
    if (responseJson?.meta?.additional_details) {
      this.userDetailsStoreInLocalStore(responseJson)
      this.pageManageInLocalstore(true, false, false);
      this.stopLoading();
      this.navigationBtnFnc('Home')
    }
  }

  googleAuthAPIResponse = (responseJson: any, errorReponse: string) => {
    if (errorReponse) {
      this.navigationBtnFnc('Home')
    }
    if (responseJson?.data && !responseJson?.meta?.message) {

      this.userDetailsStoreInLocalStore(responseJson)
      if (responseJson?.meta?.additional_details) {
        this.pageManageInLocalstore(true, false, false);
        this.stopLoading();
        this.navigationBtnFnc('Home')
        window.location.reload();
      } else {
        this.pageManageInLocalstore(false, false, true);
        this.stopLoading();
      }

    }
    if (responseJson?.meta?.additional_details) {
      this.userDetailsStoreInLocalStore(responseJson)
      this.pageManageInLocalstore(true, false, false);
      this.stopLoading();
      this.navigationBtnFnc('Home')
      window.location.reload();
    }
  }

  userProfileUpdateAPIResponse = (responseJson: any, errorReponse: string) => {
    if (errorReponse) {
      this.navigationBtnFnc('Home')
    }
    if (responseJson?.data) {
      const user = {
        id: responseJson?.data?.id,
        type: responseJson?.data?.type,
        name: responseJson?.data?.attributes?.full_name,
        email: responseJson?.data?.attributes?.email,
        image: responseJson?.data?.attributes?.image
      }
      localStorage.setItem('id', responseJson?.data?.id);
      localStorage.setItem('user', JSON.stringify(user));
      this.pageManageInLocalstore(true, false, false);
      this.stopLoading();
      this.navigationBtnFnc('Home')
    }
  }
  // herper function
  //  <---------- function for page state management in localstore {rf} ---------->
  pageManageInLocalstore = (mobileInputPage: boolean, otpInputPage: boolean, profileDetailsInputPage: boolean) => {
    this.setState(
      {
        mobileNumberInputPageEnable: mobileInputPage,
        otpInputPageEnable: otpInputPage,
        describeInputPageEnable: profileDetailsInputPage,
      }
    )
    localStorage.setItem('mobileNumberInputPageEnable', mobileInputPage.toString())
    localStorage.setItem('otpInputPageEnable', otpInputPage.toString())
    localStorage.setItem('describeInputPageEnable', profileDetailsInputPage.toString())
  }

  navigationBtnFnc = (path: any) => {
    this.props?.navigation?.navigate(path);
  }


  handleContinue = (otp: string) => {
    this.setState({ guestUser: true });
    this.mobileOtpVerification(otp)
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  otpExpire = (respMsg: any) => {
    this.setState({ expireOTP: respMsg, isExpireOTP: true })

    setTimeout(() => {
      localStorage.clear();
      this.setState({ isExpireOTP: false })
      this.pageManageInLocalstore(true, false, false);
      this.navigationBtnFnc('UserSignUpScreenWeb');
      window.location.reload()
    }, 3000)
  }

  // Customizable Area End
}