import React from 'react'
import ScrollingContainer from '../../../../components/src/ScrollingContainer'
import { Typography, Grid, IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { sideBarBanner } from '../../../recommendationengine/src/assets'
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const useStyles: any = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '4rem 0',
        [theme.breakpoints.down("sm")]: {
            margin: '3rem 0',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    // navigation side bar 
    navigationSideBar: {
        backgroundImage: `url(${sideBarBanner})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'inherit',
        height: '1000px',
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    profileTopBanner: {
        marginTop: "-1px",
        [theme.breakpoints.only("sm")]: {
            marginTop: "20px"
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: "12px"
        },
    },

    addNewMemberContainer: {
        margin: '1.5rem 25px 1.5rem 25px',
    },
    shortDescription: {
        color: '#455A64',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "33px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            lineHeight: "20px",
        },
    },
    headingTextContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        margin: '1.5rem 0 1.5rem 0',
        [theme.breakpoints.down("sm")]: {
            alignItem: "baseline",
        },
    },
    pageHeadingText: {
        color: '#37006E',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 600,
        fontSize: "25px",
        lineHeight: "33px",
        textTransform: 'capitalize',
        [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
            lineHeight: "12px",
        },
    },
    backButtonText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "22px",
        lineHeight: "12px",
        color: "#FF5050",
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",

        },
    },
    bmiToogleText: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "12px",
        color: "#000000",
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",

        },
    },
    AvatarSize: {
        height: 64, width: 64,
        [theme.breakpoints.down("xs")]: {
            height: 48, width: 48,

        },
    },
    bmiScroeDelBtn: {
        justifyContent: "space-evenly",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "space-between",
        },
    },

    // add member list css
    linearGradientBorderColorContainer: {
        margin: '1.5rem 0',
        border: "double 2px transparent",
        borderRadius: '10px',
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(80deg,#38006F,#FF5050)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        width: '100%'
    },
    familyMemberName: {
        color: '#2E445C',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '24px',
        [theme.breakpoints.only("xs")]: {
            fontSize: '15px',
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: '17px',
        },
    },
    familyMemberNameGender: {
        fontSize: "14px",
        textTransform: "capitalize",
        color: '#A8A8A8'
    },
    familyMemberSubText: {
        color: '#F89B86',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '16px',
        textTransform: "capitalize",
        [theme.breakpoints.only("xs")]: {
            fontSize: '12px',
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: '14px',
        },
    },
    bmiNumberText: {
        color: '#41479B',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '30px',
        [theme.breakpoints.only("xs")]: {
            fontSize: '15px',
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: '17px',
        },
    },
    bmiText: {
        color: '#ADADAD',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '15px',
        [theme.breakpoints.only("xs")]: {
            fontSize: '11px',
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: '11px',
        },
    },
    deleteIconBtn: {
        color: '#FF3939', border: '1px solid #FF784B',
        width: '40px',
        height: '40px',
        [theme.breakpoints.only("xs")]: {
            width: '30px',
            height: '30px',
        }
    },
    deleteIconFontSize: {
        [theme.breakpoints.only("xs")]: {
            fontSize: '14px'
        }
    },
    scrollingBmiContainer: {
        maxHeight: 640,
        overflowY: "scroll",
        overflow: "hidden",
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.00)',
        }
    },
    NoProductNofificationText: {
        color: '#FF3939',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '23px',
        marginTop: 16,
        [theme.breakpoints.down("sm")]: {
            marginTop: 8,
            fontSize: '14px',
        },
    },
}));

interface Props {
    profileLists: any;
    handleDeleteModalOpen: (id: any) => void;
    navigationProps: any;
}

export const BmiNewProfileMemberLists = ({ profileLists, handleDeleteModalOpen, navigationProps }: Props) => {
    const classes = useStyles();
    const [t] = useTranslation(["BMiCalculatorLng"]);
    return (
        <>
            {profileLists?.length < 1 ?
                <Typography className={classes.NoProductNofificationText}>No Added Members Found, <Link to="/AddNewMemberPageWeb">Add a Member</Link></Typography> :
                <ScrollingContainer>
                    {profileLists?.map((profile: any, key: any) => {
                        return (
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                className={classes.linearGradientBorderColorContainer}
                                spacing={2}
                                key={key}
                            >
                                <Grid item xs={8} onClick={() => {
                                    navigationProps?.history?.push({
                                        pathname: '/BmiCalculatorDashboardWebPage',
                                        state: profile.id
                                    })
                                }} style={{ cursor: "pointer" }}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item xs={4} sm={2} md={2} lg={2} xl={1}>
                                            <Avatar alt={profile?.attributes?.full_name} src={profile?.attributes?.image} className={
                                                classes.AvatarSize
                                            } />
                                        </Grid>
                                        <Grid item xs={8} sm={10} md={10} lg={10} xl={11}>
                                            <Typography className={classes.familyMemberName}>{profile?.attributes?.full_name}<span className={classes.familyMemberNameGender}> ({profile?.attributes?.gender}, {profile?.attributes?.age})</span></Typography>
                                            <Typography className={classes.familyMemberSubText}><span style={{ color: '#A8A8A8', marginRight: '10px' }}>({profile?.attributes?.relation})</span>{t("Weight")}: {profile?.attributes?.weight}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid
                                        container
                                        direction="row"
                                        className={classes.bmiScroeDelBtn}
                                    >
                                        <div style={{ textAlign: 'center' }}>
                                            <Typography className={classes.bmiNumberText}>
                                                {profile?.attributes?.bmi_result}
                                            </Typography>
                                            <Typography className={classes.bmiText}>BMI</Typography>
                                        </div>
                                        <IconButton aria-label="delete" data-testid="handleDeleteModalOpenFun" className={classes.deleteIconBtn} onClick={() => { handleDeleteModalOpen(profile?.attributes?.id) }}>
                                            <DeleteIcon className={classes.deleteIconFontSize} />
                                        </IconButton>
                                    </Grid>

                                </Grid>
                            </Grid>
                        )
                    })}
                </ScrollingContainer>
            }
        </>



    )
}
