import React from 'react';
import Loader from '../../../components/src/Loader';
import ProductDetailsScreenWebController from './ProductDetailsScreenWebController';
import { ReportThis, Compare, rightArrow, LeftArrow, ratingScaleImg, successfullyImg } from './assets';
import './productDetailsPagestyle.css'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import "./CompareProducts.web.css";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import {
    Grid,
    Button,
    Typography
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import ProductDetailsPageFAQ from './ProductDetailsPageFAQ';
import Products from './Products';
import HealthyFoodBasketButton from './HealthyFoodBasketButton';
import ProductRatingToolTip from './ProductRatingToolTip';
import GoodNotSoGoodWeb from './goodNotSoGood.web';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';




class ProductDetailsScreenWeb extends ProductDetailsScreenWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
            loadingNiqChoicesProduct,
            products,
            niqProducts,
            addFoodBasketApiResponseText,
            modalOpen,
            isLogin
        } = this.state;
        const items = ["A", "B", "C", "D", "E"];

        const { classes, t } = this.props;
        if (this.state.loading) {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <Loader loading={loading} />
                </Grid>
            );
        }

        return (
            <>
                <div className={classes.root}>
                    <div className={classes.topBackBtnContainer}>
                        <Button
                            className={`${classes.productTextPoppins500} ${classes.topBackButton}`}
                            data-testid="backButton"
                            onClick={() => this.backButton()}
                        >
                            {t("Back")}
                        </Button>

                    </div>
                    <Grid container direction="row"
                        spacing={3}
                        className={classes.productDteailsContent}>
                        <Grid item sm={12} md={4} className={classes.productImageGrid}>
                            <div className={classes.productImageContent} style={{
                                backgroundPosition: "center",
                                backgroundImage: `url(${products?.attributes?.image})`,
                                backgroundSize: "cover",
                                backgroundRepeat: 'no-repeat',
                            }}>
                                <div className={classes.productImageBorder}>
                                    <div className={classes.productImageDiv}>
                                        <img src={products?.attributes?.image} className={classes.productImage} alt={products?.attributes?.product_name} style={{ width: '50%' }} />
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={8} style={{ width: '100%' }}>
                            <Typography className={`${classes.productNameText} ${classes.productTextMontserrat600}`}>{products?.attributes?.product_name}</Typography>
                            <Typography className={`${classes.productTextPoppins500} ${classes.productTypeText}`}>{products?.attributes?.category_type}</Typography>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item sm={12} md={10} style={{ width: '100%' }}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        className={classes.productCategory}
                                    >
                                        <div style={{ padding: '1.5rem', width: '100%' }} >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                className={classes.nutValueConteiner}
                                            >
                                                <img src={LeftArrow} alt="LeftArrow" />
                                                <Typography className={`${classes.nutValueText} ${classes.scaleText}`}>{t("Nutritive_value_scale")}</Typography>
                                                <img src={rightArrow} alt="rightArrow" />
                                            </Grid>
                                            {/* niq score scale grid here */}
                                            <Grid container >
                                                {
                                                    items.map((item: any, index: any) => (
                                                        <ProductRatingToolTip itemRating={products?.attributes?.product_rating} item={item} key={index} >
                                                            <Grid item xs direction="column"
                                                                justifyContent="center"
                                                                alignItems="center" style={{ display: 'flex' }} container>
                                                                <Typography className={`child_${item} ${classes.child_1} ${classes.childTextStyle} ${item === products?.attributes?.product_rating && `bgColor_${item}`}`}>{item}</Typography>
                                                                <img src={ratingScaleImg} alt="" style={{ width: '103%' }} />
                                                            </Grid>
                                                        </ProductRatingToolTip>
                                                    ))
                                                }
                                            </Grid>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="flex-start"

                                            >
                                                <Typography className={`${classes.scaleTextHighest} ${classes.scaleText}`}>{t("Highest")}</Typography>
                                                <Typography className={`${classes.scaleText} ${classes.scaleTextLowest}`}>{t("Lowest")}</Typography>
                                            </Grid>

                                            <div className={classes.niqTextContent}>
                                                <Typography className={`${classes.scaleText} ${classes.niqScoreText}`} style={{ fontSize: 14 }}>
                                                    N<sup>IQ</sup> {t("Score_is_a_scientific_algorithm_to_simplify_nutritional")} <br /> {t("facts_for_consumers")}. <Link to="#" style={{ color: '#5F338B', textDecoration: 'underline' }}>{t("Know_more")}</Link>
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid item sm={12} md={2} style={{ width: '100%' }} >
                                    <Grid
                                        container
                                        justifyContent="space-around"
                                        alignItems="center"
                                        className={classes.productAction}

                                    >
                                        <div className={classes.actionItem}
                                            onClick={()=>this.isCompareProductTrue(products?.attributes?.compare_product)} data-testid="addCompareBtn">
                                            <img src={Compare} alt="Compare" style={{
                                                filter: this.state.allComparedProducts.find((e: any) => e?.attributes?.product_id == localStorage.getItem('proId')) ? 'invert(31%) sepia(100%) saturate(3749%) hue-rotate(345deg) brightness(129%) contrast(104%)' : 'none'
                                            }} />
                                            <Typography style={{
                                                fontFamily: `'Poppins', sans-serif`,
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '10px',
                                                color: '#757D8A',
                                                marginTop: 10,
                                            }}>{t("Compare")}</Typography>
                                        </div>
                                        <div className={classes.actionItem}
                                            data-testid="addFavProductBtn"
                                            onClick={() => isLogin ? this.handleFavouriteProduct(this.state.products?.attributes?.id, this.state.products?.attributes?.added_to_fav) : this.handleLoginNotifiModalOpen()}
                                        >
                                            {this.checkStatusfav(this.state.products?.attributes?.added_to_fav)}
                                            <Typography style={{
                                                fontFamily: `'Poppins', sans-serif`,
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '10px',
                                                color: '#757D8A',
                                                marginTop: 10,
                                            }}>{t("Favourite")}</Typography>
                                        </div>
                                        <div className={classes.actionItem} data-testid="reportProductBtn" onClick={() => {
                                            this.props.navigation.history.push({ pathname: "/ProductReportFormWebPage", state: { products }, search: `?proid=${products?.attributes?.id}` })
                                        }}>
                                            <img src={ReportThis} alt="Report This" />
                                            <Typography style={{
                                                fontFamily: `'Poppins', sans-serif`,
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                fontSize: '12px',
                                                lineHeight: '10px',
                                                color: '#455A64',
                                                textAlign: 'center',
                                                letterSpacing: '-0.01em',
                                                marginTop: 10,
                                            }}>{t("Report_This")}</Typography>

                                        </div>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    {/* @ts-ignore */}
                    <GoodNotSoGoodWeb />

                    <HealthyFoodBasketButton
                        foodProductId={products?.attributes?.id}
                        addFoodBasketApiCall={this.addFoodBasket}
                        addFoodBasketloader={this.state.addFoodBasketloader}
                        foodBasketLists={this.state.foodBasketLists}
                        foodBasketListLoader={this.state.foodBasketListLoader}
                        navigationBtnFnc={this.navigationBtnFnc}
                        addFoodBasketApiResponseText={addFoodBasketApiResponseText}
                        modalOpen={modalOpen}
                        handleModalClose={this.handleModalClose}
                        isLogin={isLogin}
                        updateFoodBasketApiCall={this.updateFoodBasketName}
                    />

                    <ProductDetailsPageFAQ />

                    <Typography className={`${classes.productTextPoppins600} ${classes.someChoicesProductText}`} >N<sup>IQ</sup> {t("Choices")}</Typography>

                    {loadingNiqChoicesProduct ? (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        > <CircularProgress />
                        </Grid>
                    ) : <Products niqProducts={niqProducts} goToProductDetailsPage={this.goToProductDetailsPage} />}

                </div>

                {/* Login Notification dialog Box start */}

                <Dialog
                    open={this.state.loginNotifiModalOpen}
                    style={{ zIndex: 9999 }}
                    aria-labelledby="form-dialog-title"
                    BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}>
                    <div className={classes.modelContent}>
                        <DialogTitle id="form-dialog-title">
                            {t("Looks_like_you_have_not_signed_up")}{' '}
                        </DialogTitle>
                        <DialogActions>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <Button variant="outlined" data-testid="signupButton" onClick={() => this.navigationBtnFnc('UserSignUpScreenWeb')} color="primary">
                                    {t("Sign_Up")}
                                </Button>
                                <Button data-testid="continueButton" onClick={this.handleLoginNotifiModalClose} variant="contained" color="primary">
                                    {t("Continue")}
                                </Button>
                            </Grid>
                        </DialogActions>
                    </div>
                </Dialog>
                {/* @ts-ignore */}
                <Modal className='compareProductsmodal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    style={{ zIndex: 9999 }}
                >
                    <Fade in={this.state.isOpen}>
                        <div className="compareProductsPaper">
                            {this.state.comparedataLimit != 5 && (
                                <img style={{ height: 70 }} src={successfullyImg} />
                            )}
                            <p className="successTextView">{this.state.comparedataLimit === 5 ? `${t("You_have_already_added_5_Products")}` : `${t("Product_added_successfully")}`}</p>
                            <Button style={{
                                color: '#fff',
                                background: '#38006D',
                                borderRadius: '30px',
                                padding: '10px 30px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                fontSize: '14px',
                                textTransform: "capitalize",
                                // margin:"auto 0"
                            }}
                                // size="large"
                                data-testid="compareModalBtn" onClick={() => {
                                    if (this.state.comparedataLimit === 5) {
                                        return this.props.navigation.navigate("CompareProducts");
                                    } else {
                                        this.addToCompareProducts()
                                        this.setState({ compareProductadded: true })
                                        this.handleClose()
                                    }
                                }
                                }
                            >
                                {this.state.comparedataLimit === 5 ? `${t("Go_to_Compare_Products")}` : `${t("Close")}`}
                            </Button>
                        </div>
                    </Fade>
                </Modal>
                
                {/* @ts-ignore */}                
                <Modal
                 style={{ display: "flex", justifyContent: "center", alignItems: "center",zIndex: 9999  }}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.compareProductAlreadyAdded}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.compareProductAlreadyAdded}>
                        
                        
                              <div style={{ background: "#fff", width: 350, height: 220, borderRadius: 20, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                                <CheckCircleOutlineIcon style={{ width: 100, height: 100, color:'#FFB946' }} />
                                <Typography
                                style={{ fontFamily: 'Poppins', fontStyle: 'normal', fontWeight: 500, fontSize: '16px', textAlign: 'center', color: '#455A64', }}
                                >{t("You_have_already_added_this_Product")}</Typography>
                                                    
                            <Button style={{
                                color: '#fff',
                                background: '#38006D',                           
                                borderRadius: '30px',
                                padding: '10px 30px',
                                marginTop:16,
                                textAlign: 'center',
                                cursor: 'pointer',
                                fontSize: '14px',
                                textTransform: "capitalize",  
                                fontFamily: `'Poppins', sans-serif`,                              
                            }}
                                data-testid="compareModalBtn" onClick={() => {
                                    if (this.state.comparedataLimit >= 2) {                                        
                                        return this.props.navigation.navigate("CompareProducts");
                                    } else {                                        
                                        this.handleClose()
                                    }
                                }
                                }
                            >
                                {this.state.comparedataLimit >= 2 ? `${t("Go_to_Compare_Products")}` : `${t("Close")}`}
                            </Button>
                        </div>
                    </Fade>
                </Modal>
                {/* login notification dialog box end */}
            </>
        );
    }
}


const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: '6rem 1rem',
            [theme.breakpoints.only("xs")]: {
                margin: '4rem 1rem',
            },
            [theme.breakpoints.only("sm")]: {
                margin: '5rem 1rem',
            },
            [theme.breakpoints.only("md")]: {
                margin: '6rem 1rem',
            },
        },

        // common css for text front {rf}
        productTextPoppins500: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500
        },
        productTextPoppins600: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600
        },
        productTextMontserrat600: {
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600
        },

        // css for backBtnDiv css
        backBtnDiv: {
            width: '100%',
        },

        productDteailsContent: {
            justifyContent: "center",
            marginBottom: '3rem'
        },
        productImageGrid: {
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '10px',
        },
        // css for product image
        productImageContent: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
        },
        productImageDiv: {
            width: '260px',
            height: '260px',
            backgroundColor: 'white',
            backgroundRepeat: 'no-repeat',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.only("xs")]: {
                width: '200px',
                height: '200px',
            },
            [theme.breakpoints.between("sm", 'md')]: {
                width: '250px',
                height: '250px',
            },
        },
        productImageBorder: {
            border: "double 5px transparent",
            borderRadius: '50%',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            [theme.breakpoints.down("sm")]: {
                marginLeft: "auto",
                marginRight: "auto",
            },
        },


        // css for product details
        productNameText: {
            color: '#455A64',
            fontSize: '28px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '15px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '18px',
            },
            [theme.breakpoints.only("md")]: {
                fontSize: '20px',
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: '23px',
            },
        },
        productTypeText: {
            color: '#FF5050',
            fontSize: '17px',
            margin: '10px 0',
            [theme.breakpoints.only("xs")]: {
                fontSize: '12px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '13px',
            },
            [theme.breakpoints.only("md")]: {
                fontSize: '15px',
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: '17px',
            },
        },

        productCategory: {
            border: "double 2px transparent",
            borderRadius: 15,
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(360deg,#440A70,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            [theme.breakpoints.down("sm")]: {
                marginBottom: 16,
                marginLeft: "auto",
                marginRight: "auto",
            },
        },
        productAction: {
            margin: ' 0 auto',
            border: "double 2px transparent",
            width: 100,
            borderRadius: 65,
            height: '350px',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(360deg,#440A70,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            flexDirection: 'column',
            [theme.breakpoints.down("sm")]: {
                width: '100%',
                height: '100px',
                flexDirection: 'row',
            },
            [theme.breakpoints.only("md")]: {
                height: '340px',
            },
        },
        actionItem: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            cursor: "pointer",
        },

        child_1: {
            height: '70px',
            width: '70px',

            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            [theme.breakpoints.only('xs')]: {
                height: '35px',
                width: '35px',
            },
            [theme.breakpoints.only('sm')]: {
                height: '45px',
                width: '45px',
            },
            [theme.breakpoints.only('md')]: {
                height: '50px',
                width: '50px',
            },
            [theme.breakpoints.only('lg')]: {
                height: '60px',
                width: '60px',
            },

        },

        childTextStyle: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '18px'
        },

        scaleText: {
            fontFamily: `'Poppins', sans-serif`, fontWeight: 500, fontSize: '11px',
        },
        scaleTextHighest: {
            color: '#008D59',
        },
        scaleTextLowest: {
            color: '#FF3939'
        },
        niqTextContent: {
            textAlign: 'center',
            marginTop: '3rem',
            marginBottom: '1.5rem'
        },

        niqScoreText: {
            color: '#968C8C'
        },
        nutValueConteiner: {
            marginTop: '0.5rem',
            marginBottom: '2rem'
        },
        nutValueText: {
            padding: '0 1rem',
            color: '#455A64'
        },

        // choices product css
        someChoicesProductText: {
            color: '#1D2F5E', fontFamily: `'Poppins', sans-serif`, fontWeight: 600, fontSize: '28px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '18px',
            },
            [theme.breakpoints.only("md")]: {
                fontSize: '22px',
            },
        },
        topBackBtnContainer: {
            width: '90%',
            margin: '0 auto',
            textAlign: 'right',
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        topBackButton: {
            border: '2px solid #FF3939',
            borderRadius: '30px',
            padding: '7px 40px',
            color: 'red',
            background: "#fff",
            '&:hover': {
                background: 'none',
            },
            [theme.breakpoints.down("sm")]: {
                padding: '1px 25px',
                marginBottom: "1rem",
            },
        },

        //sai dev
        modelContent: {
            padding: '2rem',
            borderRadius: '1rem',
        },
    });
// Customizable Area End

export default withStyles(styles, { withTheme: true })(withTranslation('ProductDetailsLang')(ProductDetailsScreenWeb));