// Customizable Area Start
import React, { Component } from "react";
// @ts-ignore
import Quagga from "quagga";
import "./BarcodeScanner.css"
class Scanner extends Component {
  componentDidMount() {
    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: document.querySelector("#interactive"), // Or '#yourElement' (optional)

          constraints: {
            height: 300,            
            width: 300,            
            facingMode: "environment",
          },
        },
        locator: {
          halfSample: true,
          patchSize: "large", // x-small, small, medium, large, x-large
          debug: {
            showCanvas: true,
            showPatches: false,
            showFoundPatches: false,
            showSkeleton: false,
            showLabels: false,
            showPatchLabels: false,
            showRemainingPatchLabels: false,
            boxFromPatches: {
              showTransformed: true,
              showTransformedBox: true,
              showBB: true,
            },
          },
        },
        numOfWorkers: 4,
        decoder: {
          readers: ["code_128_reader","ean_reader",],
          debug: {
            drawBoundingBox: true,
            showFrequency: true,
            drawScanline: true,
            showPattern: true,
          },
        },
        locate: true,
      },
      function(err: any) {
        if (err) {
          return
        }
        Quagga.start();
        
        
      }
    );
    Quagga.onDetected(this._onDetected);
  }

  componentWillUnmount() {
    Quagga.stop();
  }

  _onDetected = (result: any) => {
    //   @ts-ignore
    this.props.onDetected(result);
    // alert(result)
  };

  render() {
    return <div id="interactive" className="viewport" />;
  }
}
export default Scanner;
// Customizable Area End
