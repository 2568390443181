// Customizable Area Start
import React from "react";
import { FavouriteProductContext } from "./FavouriteProductContext";

import FavouriteProductContextController, { Props } from "./FavouriteProductContextController";

class FavouriteProductContextProvider extends FavouriteProductContextController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            // @ts-ignore
            <FavouriteProductContext.Provider
                value={{ ...this.state, ...this }}>
                {this.props?.children}
            </FavouriteProductContext.Provider>
        );
    }
}

export default FavouriteProductContextProvider;
// Customizable Area End
