import React from 'react';
// Customizable Area Start
import AddProductControllerWeb from './AddProductController.web';
import { Typography, Grid, IconButton, Button, InputBase, InputAdornment, Select, MenuItem } from "@material-ui/core";
import { sideBarBanner } from '../../recommendationengine/src/assets';
import { topBanner, approvedIcon } from "./assets"
import SideNavbarWeb from '../../../components/src/SideNavbarWeb';
import { createStyles, withStyles, Theme, } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import { ErrorValidationMessage } from '../../../components/src/ErrorValidationMessage';
import { AddProductErrorValidation } from './AddProductErrorValidation';
import TextField from '@material-ui/core/TextField';
import ImageCroppingWeb from "../../../components/src/ImageCropping.web"


export const styles = (theme: Theme) =>
    createStyles({
        rootMain: {
            flexGrow: 1,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        profileTopBanner: {
            marginTop: "-1px",
            marginLeft: "-1px",
            [theme.breakpoints.only("sm")]: {
                marginTop: "15px"
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: "7px"
            },
        },
        addNewMemberContainer: {
            margin: '1.5rem 25px 1.5rem 25px',
            [theme.breakpoints.down("xs")]: {
                margin: '10px',
            },
        },
        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
                margin: '1rem 0',
            },
        },
        pageHeadingText: {
            color: '#37006E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "33px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: "16px",
            },
        },
        backButtonText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF5050",
            cursor: 'pointer',
            textTransform: "capitalize",
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
            [theme.breakpoints.down("xs")]: {
                fontSize: "12px",
            },
        },
        FoodType: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '21px',
            textTransform: 'capitalize',
            color: '#757D8A',
            lineHeight: '2.5rem',
            [theme.breakpoints.down("md")]: {
                fontSize: "18px",
                // marginTop:5,
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                marginTop: 5,
            },
        },
        foodBasketTypeTextField: {
            marginTop: 14,
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',
            border: "double 1px transparent",
            borderRadius: 4,
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(0deg,#38006D,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            height: '55px',
            [theme.breakpoints.down("sm")]: {
                marginTop: 7,
                marginBottom: 16,
                marginLeft: "auto",
                marginRight: "auto",
            },
        },
        imageWrapperContainer: {
            margin: "12px", display: "flex", flexDirection: 'column', alignItems: 'center'
        },
        imageContainer: {
            position: 'relative',
            minWidth: 236,
            width: "100%",
            height: 180,
            display: 'inline-block',
            borderRadius: '3px',
            border: '2px dotted #fff',
            background: 'linear-gradient(#f0f0f0 0 0) padding-box,linear-gradient(180deg, #41479B 0%, #FF3939 100%) border-box',

        },
        uploadIcon: {
            color: "#757D8A", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)"
        },
        uplaodImageTitle: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '18px',
            textTransform: 'capitalize',
            color: '#757D8A',
            marginTop: "6px",
            textAlign: "center",
            [theme.breakpoints.down("sm")]: {
                fontSize: '16px',
            },
        },
        productButton: {
            fontFamily: `'Montserrat', sans-serif`,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            background: '#38006D',
            borderRadius: "44px",
            padding: '16px 110px',
            marginTop: '4rem',
            '&:hover': {
                background: '#12125c',
                border: 'none',
            },
            [theme.breakpoints.down("md")]: {
                padding: '12px 90px',
                fontSize: '16px',
                marginTop: '2.5rem',
            },
            [theme.breakpoints.down("sm")]: {
                padding: '12px 90px',
                fontSize: '14px',
                marginTop: '2rem',
            },
        },
        modelContent: {
            padding: '2rem',
            borderRadius: '1rem',
            [theme.breakpoints.down("sm")]: {
                padding: '1rem',
            },
        },
        successText: {
            textAlign: 'center',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: "28px",
            margin: '1rem 0',
            color: "#455A64",
            [theme.breakpoints.down("md")]: {
                fontSize: "22px",
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: "16px",
            },
        },
        secondDilogBoxBtn: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: "17px",
            lineHeight: "28px",
            padding: '0.5rem 1.3rem',
            borderRadius: '44px',
            backgroundColor: '#12125c',
            textTransform: "capitalize",
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "23px",
                padding: '0.3rem 1.6rem',
            },
            [theme.breakpoints.down("xs")]: {
                padding: '0.2rem 1.3rem',
            },
            '&:hover': {
                background: '#12125c',
                border: 'none',
            },

        },
        cancelDilogBoxBtn: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: "17px",
            lineHeight: "28px",
            padding: '0.5rem 1.8rem',
            borderRadius: '44px',
            color: '#12125c',
            border: '2px solid #12125c',
            textTransform: "capitalize",
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "23px",
                padding: '0.3rem 1.6rem',
            },
            [theme.breakpoints.down("xs")]: {
                padding: '0.1rem 1.3rem',
            },
            '&:hover': {
                background: '#ffff',
            },

        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(2),
            color: '#455A64',

        },
        approvedIcon: {
            width: 96, height: 96,
            [theme.breakpoints.down("md")]: {
                width: 64, height: 64,
            },
            [theme.breakpoints.down("xs")]: {
                width: 48, height: 48,
            },
        },
        errorvalidationText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: 'normal',
            fontWeight: 500,
            color: '#FF3939',
            fontSize: '15px',
            marginTop: '0.785rem',
        },
        icon: {
            fill: '#212529',
        },
        inputFieldWithLinearGradient: {
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',
            border: "double 1px transparent",
            borderRadius: 4,
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(0deg,#38006D,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            backgroundColor: 'red',
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',
                border: "double 1px transparent",
                borderRadius: 4,
                backgroundImage:
                    "transperant linear-gradient(0deg,#38006D,#FF3939)",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',
                border: "double 1px transparent",
                borderRadius: 4,
                backgroundImage:
                    "transperant linear-gradient(0deg,#38006D,#FF3939)",
            },
        },
        inputContainer: {
            margin: '0.6rem 0'
        },
    });
// Customizable Area Stop
class AddProductWeb extends AddProductControllerWeb {
    // Customizable Area Start
    render() {
        const { classes, t } = this.props;
        const Unit = ['gm', 'kg'];
        return (
            <div className={classes.rootMain}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <img src={topBanner} alt="bgImage" style={{ width: "100%" }} className={classes.profileTopBanner} />
                        <div className={classes.addNewMemberContainer}>
                            <Button variant="text" color="primary" className={classes.backButtonText} onClick={this.backButton} data-testid='backButton'>{t("Back")}</Button>
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>{t("Add_A_PRODUCT")}</Typography>
                            </div>
                            {this.state.loading ? (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CircularProgress />
                                </Grid>
                            ) : (<>

                                <div className={classes.inputContainer} >
                                    <Typography className={classes.FoodType}>{t("Name_of_the_product")}</Typography>
                                    <TextField variant='outlined'
                                        id="Name-of-the-product"
                                        placeholder={t("Enter_Product_Name")}
                                        fullWidth
                                        className={classes.inputFieldWithLinearGradient}
                                        value={this.state.productName}
                                        onChange={(event: React.ChangeEvent<{ value: any }>) => this.handleNameFieldValueChange(event)}
                                        onKeyPress={(event) => { this.specialCharacterVlidation(event) }}
                                    />
                                    <ErrorValidationMessage errorState={this.state.productNameValidation} errorMessage={t("Enter_the_Product_Name")} />

                                </div>

                                <div className={classes.inputContainer}>
                                    <Typography className={classes.FoodType}>{t("Weight_of_the_product")}</Typography>
                                    <div className={classes.foodBasketTypeTextField} style={{ marginTop: '0' }}>
                                        <InputBase
                                            data-testid="Weight-of-the-product"
                                            placeholder={t("weight_of_Product")}
                                            fullWidth
                                            style={{ backgroundColor: 'transparent', padding: '0.7rem' }}
                                            value={this.state.productWeight}
                                            type="number"
                                            onChange={(event: any) => this.setState({ productWeight: event.target.value, productWeightValidation: false })}
                                            onKeyPress={(event: any) => { this.handlePositiveDecimalNumberOnKeyPress(event) }}
                                            onKeyDown={(event: any) => { this.disableDownKey(event) }}
                                            endAdornment={<InputAdornment position="end" data-testid='units-of-weight'>
                                                <div style={{ background: "#FF3939", height: 56, width: 76, marginRight: "-12px", borderRadius: 4, position: "relative", display: "inline-block" }}>
                                                    <Select
                                                        disableUnderline
                                                        autoFocus={false}
                                                        value={this.state.productUnit}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        onChange={
                                                            /* istanbul ignore next */
                                                            (event: any) => { this.setState({ productUnit: event.target.value }) }
                                                        }
                                                        fullWidth
                                                        inputProps={{
                                                            classes: {
                                                                icon: classes.icon,
                                                            },
                                                            "data-testid": "units"
                                                        }}

                                                        style={{
                                                            color: '#212529',
                                                            fontFamily: `'Montserrat', sans-serif`,
                                                            fontStyle: "normal",
                                                            fontWeight: 500,
                                                            backgroundColor: '#fff',
                                                            height: '100%',
                                                            padding: '0 0.7rem',
                                                            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',
                                                        }}
                                                        MenuProps={{
                                                            MenuListProps: {
                                                                disablePadding: true,
                                                            }
                                                        }}
                                                    >
                                                        {Unit.map((item: any, key: any) => {
                                                            return (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={item}
                                                                    style={{
                                                                        color: '#212529',
                                                                        fontFamily: `'Montserrat', sans-serif`,
                                                                        fontStyle: "normal",
                                                                        fontWeight: 700,
                                                                        backgroundColor: '#fff',
                                                                        height: '100%',
                                                                    }}
                                                                >
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>
                                            </InputAdornment>}
                                        />
                                    </div>
                                    <ErrorValidationMessage errorState={this.state.productWeightValidation} errorMessage={t("Enter_the_Product_Weight")} />
                                </div>

                                <div className={classes.inputContainer}>
                                    <Typography className={classes.FoodType}>{t("Reference_Url_of_the_product_Optional")}</Typography>
                                    <TextField variant='outlined'
                                        fullWidth
                                        id="Reference-Url"
                                        placeholder="https://www.amazon.com/product-name"
                                        value={this.state.refernceURL}
                                        className={classes.inputFieldWithLinearGradient}
                                        onKeyPress={(event) => { this.specialCharacterVlidation(event) }}
                                        onChange={(event: React.ChangeEvent<{ value: any }>) => {
                                            this.setState({ refernceURL: event.target.value })
                                            this.validateUrl(event.target.value)
                                        }}
                                    />
                                    <ErrorValidationMessage errorState={this.state.productUrlValidation} errorMessage={t("Invalid_Url")} />
                                </div>

                                <div className={classes.inputContainer}>
                                    <Typography className={classes.FoodType}>{t("Upload_product_image")}</Typography>

                                    <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start">

                                        <Grid item className={classes.imageWrapperContainer}>
                                            <div className={classes.imageContainer}>
                                                <ImageCroppingWeb croppedImageFunction={this.FrontImage} removeImage={this.clearFrontPackImage} />
                                            </div>
                                            <AddProductErrorValidation errorState={this.state.productFrontImageValidation} errorMessage={t("Please_Select_Image")} placeholderMessage={t("Front_Of_Pack_photo")} />
                                            <div style={{ textAlign: 'center' }}>
                                                <ErrorValidationMessage errorState={this.state.invalidFrontPackImageFileType} errorMessage={t("Invalid_file_type")} />
                                            </div>

                                        </Grid>

                                        <Grid item className={classes.imageWrapperContainer}>
                                            <div className={classes.imageContainer}>
                                                <ImageCroppingWeb croppedImageFunction={this.nutritionalImage} removeImage={this.clearnutritionalImage} />
                                            </div>
                                            {
                                                this.state.productNutritionImageValidation ? <Typography style={{ textAlign: 'center' }} className={classes.errorvalidationText} >{t("Please_Select_Image")}</Typography> : <Typography className={classes.uplaodImageTitle}>{t("Nutritional_Facts_Image")}<br /> {t("Of_The_Product")}</Typography>
                                            }
                                            <div style={{ textAlign: 'center' }}>
                                                <ErrorValidationMessage errorState={this.state.invalidNutritionImageFileType} errorMessage={t("Invalid_file_type")} />
                                            </div>

                                        </Grid>

                                        <Grid item className={classes.imageWrapperContainer}>
                                            <div className={classes.imageContainer}>
                                                <ImageCroppingWeb croppedImageFunction={this.ingredientsImage} removeImage={this.clearingredientsImage} />
                                            </div>
                                            <AddProductErrorValidation errorState={this.state.productIngredientsImageValidation} errorMessage={t("Please_Select_Image")} placeholderMessage={t("Ingredient_List_image")} />
                                            <div style={{ textAlign: 'center' }}>
                                                <ErrorValidationMessage errorState={this.state.invalidIngredientsImageFileType} errorMessage={t("Invalid_file_type")} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className={classes.inputContainer}>
                                    <Typography className={classes.FoodType}>{t("Upload_the_barcode_image_of_the_product")}</Typography>

                                    <Grid container >
                                        <Grid item style={{ margin: "12px", width: "100%" }}>
                                            <div className={classes.imageContainer}>
                                                <ImageCroppingWeb croppedImageFunction={this.barcodeImage} removeImage={this.clearbarcodeImage} />
                                            </div>
                                            <AddProductErrorValidation errorState={this.state.barcodeImageValidation} errorMessage={t("Please_Select_Image")} placeholderMessage={t("In_case_of_Hazy_or_poor_photo_Please_reupload_the_clear_images")} />
                                            <div style={{ textAlign: 'center' }}>
                                                <ErrorValidationMessage errorState={this.state.invalidBarcodeImageFileType} errorMessage={t("Invalid_file_type")} />
                                            </div>
                                        </Grid>

                                    </Grid>
                                </div>


                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        type="submit"
                                        onClick={this.AddProduct}
                                        className={classes.productButton} >
                                        {t("SUBMIT")}
                                    </Button>
                                </div>
                            </>)}
                        </div>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.isSubmitModal}
                    aria-labelledby="form-dialog-title"
                    BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
                >
                    <div className={classes.modelContent}>
                        <MuiDialogTitle disableTypography>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={() => { this.setState({ isSubmitModal: false }) }} data-testid="closeSubmitModal">
                                <HighlightOffIcon />
                            </IconButton>
                        </MuiDialogTitle>

                        <DialogContent style={{ textAlign: 'center' }}>
                            <img src={approvedIcon} alt={'approvedIcon'} className={classes.approvedIcon} />
                            <Typography className={classes.successText}>{t("Thank_you_for_your_input_We_will")} <br /> {t("validate_the_same")}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <Button variant="outlined" color="primary" className={classes.cancelDilogBoxBtn} onClick={this.backButton}>
                                    {t("Back")}
                                </Button>
                                <Button variant="contained" color="primary" className={classes.secondDilogBoxBtn} onClick={() => { this.setState({ isSubmitModal: false }) }} data-testid="closeSubmitModal">
                                    {t("Add_Another")}
                                </Button>
                            </Grid>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(withTranslation('ProductDetailsLang')(AddProductWeb));