import React from 'react';
import HealthPointWebController from './HealthPointWebController';
import { Typography, Grid } from "@material-ui/core";
import { topHeaderBanner, sideBarBanner } from '../assets';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import HealthBasketList from '../../../../components/src/healthComponents/HealthBasketList';
import CustomizedToggleSwitche from '../../../../components/src/CustomizedToggleSwitche';
import { withTranslation } from 'react-i18next';


const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '3.5rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        profileTopBanner: {
            backgroundImage: `url(${topHeaderBanner})`,
            // backgroundImage: `urls('${topHeaderBanner}')`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '255px',
            [theme.breakpoints.down("sm")]: {
                height: '170px',
            },
            [theme.breakpoints.down("xs")]: {
                height: '100px',
            },
        },

        // css for textContainer
        textContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        headdingText: {
            fontFamily: `'Montserrat', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "33px",
            color: "#37006E",
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
        },
        healthPointText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF7900",
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        FoodTypeUnderline: {
            textUnderlineOffset: "0.5rem",
            textDecoration: "underline",
            textDecorationColor: "#fb951a61",
        },

        // css for basketContainer
        basketContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },

        NoProductNofificationText: {
            color: '#FF3939',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '23px',
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px',
            },
        },
        // health pointer info css
        healthPointInfoContainer: {
            textAlign: 'center',
            padding: '4rem',
            borderRadius: '1rem',
            background: `linear-gradient(90.28deg, #38006D -15.27%, #FF3939 237.07%)`,

        },
        healthPontValue: {
            color: '#ffff',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontSize: '55px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '35px',
            },
        },
        healthPontText: {
            color: '#ffff',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '23px',
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: '16px',
            },
        },
    });

class HealthPointsWebPage extends HealthPointWebController {
    // Customizable Area Start
    render() {
        const {
            foodBasketListLoader,
            foodBasketLists
        } = this.state;

        const { classes, t } = this.props;
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={false} sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <div className={classes.profileTopBanner}>
                        </div>
                        <div className={classes.basketContainer}>
                            <CustomizedToggleSwitche checked={this.state.switchChecked} onChange={this.toggleChecked} leftToggleText={t("Health_Points")} rightToggleText={t("Leader_Board")} />

                            <div className={classes.textContainer}>
                                <Typography className={`${classes.FoodTypeUnderline} ${classes.headdingText}`} variant='h1'>
                                    {t("Health_Points")}
                                </Typography>
                            </div>
                            <div className={classes.healthPointInfoContainer}>
                                <Typography className={classes.healthPontValue}>4589</Typography>
                                <Typography className={classes.healthPontText}>{t("Your_Health_Points")}</Typography>
                            </div>
                            {
                                this.state.noFoodBasketNotification && <Typography className={classes.NoProductNofificationText}>No products added here, <Link to="/">Create one</Link> </Typography>
                            }
                            {
                                foodBasketListLoader && !this.state.noFoodBasketNotification ? (
                                    <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div>
                                ) : (
                                    foodBasketLists.map((item: any, key: any) => {
                                        return (
                                            <HealthBasketList item={item} redirectPath='HealthPointDetailsPageWeb' deleteFunction={this.deleteFoodBasket} key={key} />
                                        )
                                    })
                                )

                            }
                        </div>
                    </Grid >
                </Grid >

            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    withTranslation('HealthyFoodBasketWebLang')(HealthPointsWebPage)
);