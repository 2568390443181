import React from 'react';
import Loader from '../../../../components/src/Loader';
import ProductReportedWebController from './ProductReportedWebController';
import { Typography, Grid } from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import { sideBarBanner, productReportTopBanner } from "../assets"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { withTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import { LoadingErrorNotificationText } from '../../../../components/src/LoadingErrorNotificationText';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: '4rem 0',
            flexGrow: 1,
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '1000px',
            backgroundSize: 'inherit',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        productReportTopBanner: {
            marginLeft: '-1px',
            marginTop: "-1px",
            [theme.breakpoints.only("sm")]: {
                marginTop: "15px"
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: "8px"
            },
        },

        productReportContainer: {
            width: '91%',
            margin: '0 auto'
        },

        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        pageHeadingText: {
            color: '#37006E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "33px",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
        },

        productImageGrid: {
            width: '100%',
            height: '500px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '10px',
        },
        productImageContent: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
        },
        productImageBorder: {
            border: "double 5px transparent",
            borderRadius: '50%',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            [theme.breakpoints.down("sm")]: {
                marginLeft: "auto",
                marginRight: "auto",
            },
        },
        productImageDiv: {
            width: '200px',
            height: '200px',
            backgroundColor: 'white',
            backgroundRepeat: 'no-repeat',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.only("xs")]: {
                width: '200px',
                height: '200px',
            },
            [theme.breakpoints.between("sm", 'md')]: {
                width: '250px',
                height: '250px',
            },
        },
        ProductName: {
            color: '#455A64',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontSize: '24px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '18px',
            },
        },

        productButton: {
            fontFamily: `'Montserrat', sans-serif`,
            color: '#FFFFFF',
            background: '#38006D',
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: '20px',
            textAlign: 'center',
            textTransform: 'uppercase',
            padding: '12px 130px',
            borderRadius: "44px",
            marginTop: '4rem',
            '&:hover': {
                border: 'none',
                background: '#12125c',
            },
            [theme.breakpoints.down("md")]: {
                fontSize: '16px',
                padding: '12px 90px',
                marginTop: '2.5rem',
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px',
                marginTop: '2rem',
                padding: '12px 90px',
            },
        },
        ProductType: {
            color: '#455A64',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '17px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '14px',
            },
        },
        LetUsKnowtext: {
            color: '#263238',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '17px',
            lineHeight: '35px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '15px',
            },
        },
        ProblemTitle: {
            color: '#757D8A',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontSize: '23px',
            lineHeight: '20px',
            marginBottom: '6px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '18px',
                lineHeight: '30px',
            },
        },
        radioInputText: {
            '& .MuiTypography-body1': {
                color: '#455A64',
                fontFamily: `'Poppins', sans-serif`,
                fontWeight: 500,
                fontSize: '16px',
            }
        },

        productInfoContainer: {
            margin: '2rem 0',
            [theme.breakpoints.only("xs")]: {
                margin: '0 0 2rem 0',
            },
            [theme.breakpoints.only("sm")]: {
                margin: '1rem 0 2rem 0',
            },
        },
        questionsMargin: {
            margin: '2rem 0'
        },
        textAreaCaontainer: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',

        },
        textCounter: {
            position: 'absolute',
            bottom: 0,
            right: 12,
            color: '#808080',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 400,
        },
        reportTextArea: {
            color: '#455A64',
            width: '100%',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 400,
            resize: 'none',
            borderRadius: '0.5rem',
            padding: '5px',
        },

        modelContent: {
            padding: '2rem',
            borderRadius: '1.5rem',
        },
        successText: {
            textAlign: 'center',
            fontFamily: `'Poppins', sans-serif`,
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: "22px",
            lineHeight: "33px",
            margin: '1rem 0',
            color: "#455A64",
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
                lineHeight: "25px",
            },
        },
        validationText: {
            color: '#FF3939',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: '15px',
            margin: '0.785rem 0'
        },
        backButtonText: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontStyle: "normal",
            lineHeight: "12px",
            fontSize: "22px",
            cursor: 'pointer',
            color: "#FF5050",
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
            },
        },
        deleteDilogBoxBtn: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: "17px",
            lineHeight: "28px",
            width: 100,
            height: 40,
            borderRadius: '0.5rem',
            backgroundColor: '#12125c',
            color: '#ffff',
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "23px",
                width: 80,
                height: 31,
            },
            '&:hover': {
                background: '#12125c',
                border: 'none',
            },

        },
    });

class ProductReportFormWebPage extends ProductReportedWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
        } = this.state;
        const { classes, t } = this.props;
        if (loading) {
            return (
                <Grid
                    container
                    justifyContent="center"
                    direction="row"
                    style={{ height: '100vh' }}
                    alignItems="center"
                >
                    <Loader loading={loading} />
                </Grid>
            );
        }
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} >
                        <img src={productReportTopBanner} alt="bgImage" style={{ width: "100%" }} className={classes.productReportTopBanner} />
                        <div className={classes.productReportContainer}>
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>{t("Report_this_product")}</Typography>
                                {
                                    this.state.viewReportProductDetails
                                        ? <Typography className={classes.backButtonText} data-testid="backbtn" onClick={() => this.backButton()}>
                                            {t("Back")}
                                        </Typography>
                                        :
                                        ""
                                }

                            </div>

                            {
                                this.state.productDetails.length === 0 ? <LoadingErrorNotificationText /> : (
                                    <div style={{ margin: '1rem 0' }}>
                                        <Grid container direction="row"
                                            spacing={3}
                                            className={classes.productDteailsContent}>
                                            <Grid item xs={12} className={classes.productImageGrid}>
                                                <div className={classes.productImageContent} style={{
                                                    backgroundPosition: "center",
                                                    backgroundImage: `url(${this.state.productDetails?.attributes?.image})`,
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: 'no-repeat'
                                                }}>
                                                    <div className={classes.productImageBorder}>
                                                        <div className={classes.productImageDiv}>
                                                            <img src={this.state.productDetails?.attributes?.image} className={classes.productImage} alt="test" style={{ width: '50%' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <div className={classes.productInfoContainer}>
                                            <Typography className={classes.ProductName}>
                                                {this.state.productDetails?.attributes?.product_name}
                                            </Typography>
                                            <Typography className={classes.ProductType}>
                                                {this.state.productDetails?.attributes?.category_type}
                                            </Typography>
                                        </div>

                                        <div>
                                            <Typography className={`${classes.LetUsKnowtext} ${classes.quesComtainerMargin}`}>
                                                {t("Let_us_know_your_problems")}
                                            </Typography>
                                            {
                                                this.state.reportQuestionList.map((question: any, key: any) => {
                                                    return (
                                                        <div key={key} className={classes.questionsMargin}>
                                                            <Typography className={classes.ProblemTitle}>
                                                                {question?.attributes?.reported_question}
                                                            </Typography>
                                                            <FormControl component="fieldset">
                                                                <RadioGroup
                                                                    aria-label="repost_question"
                                                                    name="question_list"
                                                                    style={{
                                                                        flexDirection: 'row'
                                                                    }}
                                                                    data-testid="redioBtnForQuestions"
                                                                    onChange={(event: any) => this.handelReportQuestionAnswer(event, question)}
                                                                    value={`${this.state.reportQuestionAnswer[key]}`}
                                                                >
                                                                    {
                                                                        question?.attributes?.reported_question_answer?.data.map((option: any, key: any) => {
                                                                            return (
                                                                                <FormControlLabel
                                                                                    value={JSON.stringify(option?.attributes?.id)}
                                                                                    disabled={this.state.viewReportProductDetails}
                                                                                    control={<Radio
                                                                                        style={{
                                                                                            color: '#607d8b'
                                                                                        }}
                                                                                    />}
                                                                                    label={option?.attributes?.reported_answer}
                                                                                    className={classes.radioInputText}
                                                                                    key={key} />
                                                                            )
                                                                        })
                                                                    }
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                this.state.reportQuestionValidation ? (<Typography className={classes.validationText}>{t("Please_select_all_questions")}</Typography>) : ''
                                            }

                                            <Typography className={classes.ProblemTitle}>
                                                {t("Any_other_problem")}
                                            </Typography>
                                            <div className={classes.textAreaCaontainer}>
                                                <TextareaAutosize
                                                    aria-label="minimum height"
                                                    // value="uyttutu"
                                                    value={this.state?.reportDescription}
                                                    minRows={8}
                                                    maxLength={500}
                                                    className={classes.reportTextArea}
                                                    data-testid="reportDescriptionTextbox"
                                                    onChange={(event: any) => this.handelreportDescription(event)}
                                                    disabled={this.state.viewReportProductDetails}
                                                />
                                                <Typography className={classes.textCounter}>{this.state.reportDescription.length}/500</Typography>
                                            </div>
                                            {
                                                this.state.reportDescriptionValidation ? (<Typography className={classes.validationText}>{t("Please_write_a_Description")}</Typography>) : ''
                                            }
                                            {this.state.firstCharIsNotLetter ? (<Typography className={classes.validationText}>{t("First_character_must_be_letter")}</Typography>) : ''}
                                        </div>

                                        {!this.state.viewReportProductDetails ?
                                            <div style={{ textAlign: 'center' }}>
                                                <Button
                                                    onClick={() => this.submitProductReport(this.state.productDetails?.id)}
                                                    data-testid="submitreportBtn"
                                                    className={classes.productButton} >
                                                    {t("Submit")}
                                                </Button>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                )
                            }

                        </div>
                    </Grid >
                </Grid >


                {/* dialog box */}

                <Dialog
                    open={this.state.notificationModal}
                    style={{ zIndex: 9999 }}
                    aria-labelledby="form-dialog-title"
                    BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
                >
                    <div className={classes.modelContent}>
                        <DialogContent style={{ textAlign: 'center' }}>
                            <CheckCircleOutlineIcon style={{ width: 100, height: 100, color: this.state.notificationText ? '#ffcc00' : '#0EAA58' }} />
                            <Typography className={classes.successText}>{this.state.notificationText ? this.state.notificationText : `${t("Product_reported_successfully")}`}</Typography>
                        </DialogContent>
                        <DialogActions style={{
                            justifyContent: "center"
                        }}>
                            <Button variant="contained" data-testid="goToSignUpPageBtn" className={classes.deleteDilogBoxBtn} onClick={() => this.navigationBtnFnc('ProductReportedWebPage')}>
                                {t("OK")}
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    withTranslation('ProductDetailsLang')(ProductReportFormWebPage)
);