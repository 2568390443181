import React from 'react';
// Customizable Area Start
import AddProductControllerWeb from './AddProductController.web';
import { Typography, Grid, Button } from "@material-ui/core";
import { sideBarBanner } from '../../recommendationengine/src/assets';
import { topBanner } from "./assets"
import SideNavbarWeb from '../../../components/src/SideNavbarWeb';
import CustomizedToggleSwitche from '../../../components/src/CustomizedToggleSwitche';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import { YourProductLists } from './YourProductLists';


const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        profileTopBanner: {
            marginTop: "-1px",
            marginLeft: "-1px",
            [theme.breakpoints.only("sm")]: {
                marginTop: "15px"
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: "7px"
            },
        },
        addNewMemberContainer: {
            margin: '1.5rem 25px 1.5rem 25px',
            [theme.breakpoints.down("xs")]: {
                margin: '10px',
            },
        },
        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
                margin: '1rem 0',
            },
        },
        pageHeadingText: {
            color: '#37006E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "33px",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: "16px",
            },
        },

        linearBgBtn: {
            backgroundImage: `linear-gradient(91.39deg, #41479B 4.42%, #FF3939 100%)`,
            color: '#FFFFFF',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "33px",
            padding: '10px 5rem',
            border: '6px',
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "13px",
                lineHeight: "12px",
                padding: '10px 3rem',
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: "12px",
                padding: '10px 1rem',
            },
        }
    });
// Customizable Area Stop
class YourProductWeb extends AddProductControllerWeb {
    // Customizable Area Start
    render() {
        const {
            loading
        } = this.state;
        const { classes, t } = this.props;

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <img src={topBanner} alt="bgImage" style={{ width: "100%" }} className={classes.profileTopBanner} />
                        <div className={classes.addNewMemberContainer}>
                            <CustomizedToggleSwitche checked={this.state.switchChecked} onChange={this.toggleChecked} leftToggleText={t("Product_Submitted")} rightToggleText={t("Products_Reported")} />
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>{t("Your_Products")}</Typography>
                                <Button variant="contained" color="primary" className={classes.linearBgBtn}
                                    onClick={() => { this.navigationBtnFnc("AddProductWeb") }}
                                    data-testid="AddProductButton"
                                >+ {t("Add_A_PRODUCT")}</Button>
                            </div>
                            {
                                loading ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> : <YourProductLists yourProductLists={this.state.yourProductList} formatTheDate={this.formatTheDate} pageProps={this.props} />
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>

        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    withTranslation('ProductDetailsLang')(YourProductWeb)
);