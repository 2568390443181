import React from 'react';
import UserProfileWebController from './UserProfileWebController';
import { Typography, Grid, Button, InputBase } from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../components/src/SideNavbarWeb';
import { sideBarBanner } from '../../recommendationengine/src/assets'
import { addNewMemberPageBanner } from './assets'
import States from './utils/states';
import { withTranslation } from 'react-i18next';
import { ErrorValidationMessage } from '../../../components/src/ErrorValidationMessage';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageInputfield from './addNewMemberFromComponents/ImageInputfield';
import RelationSelectField from './addNewMemberFromComponents/RelationSelectField';
import ActivityLevelSelectField from './addNewMemberFromComponents/ActivityLevelSelectField';
import GenderSelectField from './addNewMemberFromComponents/GenderSelectField';
import StateSelectField from './addNewMemberFromComponents/StateSelectField';
import CitySelectfield from './addNewMemberFromComponents/CitySelectfield';
import TextField from '@material-ui/core/TextField';






class AddNewMemberPageWeb extends UserProfileWebController {
    // Customizable Area Start
    render() {
        const {
            loading
        } = this.state;
        const { classes, t } = this.props;

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };
        const ActivityLevel = [
            { label: `${t("High")}`, value: 'high' },
            { label: `${t("Medium")}`, value: 'medium' },
            { label: `${t("Low")}`, value: 'low' }
        ]
        const Gender = [
            { label: `${t("Male")}`, value: 'male' },
            { label: `${t("Female")}`, value: 'female' },
            { label: `${t("Other")}`, value: 'other' }
        ]

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} >
                        <div className={classes.profileTopBanner}>
                        </div>
                        <div className={classes.addNewMemberContainer}>
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>{t("Add_a_Member")}</Typography>
                                <Typography className={classes.backButtonText} data-testid="backbtn" onClick={() => this.goBack()}>
                                    {t("Back")}
                                </Typography>
                            </div>
                            <div>
                                <Typography className={classes.shortDescription}>
                                    {t("pageDescription")}
                                </Typography>
                            </div>

                            {loading ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> : (
                                <div>
                                    <Grid container spacing={3} style={{ marginTop: '2rem' }}>
                                        <Grid item xs={12} >

                                            {/* here image upload and update codes start {rf} */}
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <ImageInputfield instance={this} />
                                            </div>

                                            <div style={{ textAlign: 'center' }}>
                                                <ErrorValidationMessage errorState={this.state.personImageValidationError} errorMessage={t("Please_Select_Image")} />
                                            </div>

                                            {/* here image upload and update codes end {rf} */}
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Full_Name")}<span className={classes.requerField}>*</span>
                                            </Typography>
                                            <TextField variant='outlined'
                                                fullWidth
                                                data-testid="nameField"
                                                placeholder={`${t("Enter_your_full_name")}`}
                                                value={this.state.fullName}
                                                className={classes.inputFieldWithLinearGradient}
                                                onKeyPress={(event) => { this.specialCharacterVlidation(event) }}
                                                onChange={(event: React.ChangeEvent<{ value: any }>) => this.handleNameFieldValueChange(event)}
                                                required
                                            />
                                            <ErrorValidationMessage errorState={this.state.fullNameValidationError} errorMessage={t("Enter_your_full_name")} />

                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Email_Id")}<span className={classes.requerField}>*</span>
                                            </Typography>
                                            <TextField variant='outlined'
                                                fullWidth
                                                data-testid="emailField"
                                                className={classes.inputFieldWithLinearGradient}
                                                type='email'
                                                placeholder={`${t("mailPlaceholder")}`}
                                                value={this.state.emailId}
                                                onChange={(event: any) => { this.handleEmailChange(event.target.value) }}
                                            />
                                            <ErrorValidationMessage errorState={this.state.emailIdValidationError} errorMessage={t("Please_enter_valid_email")} />
                                            {
                                                this.state.isEmailIdvalid ? <Typography className={classes.errorvalidationText}>{this.state.isEmailIdvalid}.</Typography> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Age")}<span className={classes.requerField}>*</span>
                                            </Typography>

                                            <TextField variant='outlined'
                                                fullWidth
                                                data-testid="ageField"
                                                placeholder={`${t("Enter_your_Age")}`}
                                                className={classes.inputFieldWithLinearGradient}
                                                type="number"
                                                value={this.state.personeAge}
                                                onChange={(event: any) => { this.handleAgeValueChange(event) }}
                                                onKeyPress={(event: any) => { this.handlePositiveNumberOnKeyPress(event) }}
                                                onKeyDown={(event: any) => { this.disableDownKey(event) }}
                                            />
                                            <ErrorValidationMessage errorState={this.state.personeAgeValidationError} errorMessage={t("Enter_your_Age")} />
                                            <ErrorValidationMessage errorState={this.state.ageRangeValidationError} errorMessage={t("Enter_valid_age_of_less_or_equal_150")} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            {/* here Gender select code start {rf} */}
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Gender")}<span className={classes.requerField}>*</span>
                                            </Typography>
                                            <GenderSelectField instance={this} Gender={Gender} MenuProps={MenuProps} />
                                            <ErrorValidationMessage errorState={this.state.genderValidationError} errorMessage={t("Select_your_Gender")} />
                                            {/* here Gender select code end {rf} */}
                                        </Grid>


                                        <Grid item xs={12} sm={6} >
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Weight")}<span className={classes.requerField}>*</span>
                                            </Typography>

                                            <TextField variant='outlined'
                                                fullWidth
                                                className={classes.inputFieldWithLinearGradient}
                                                data-testid="weightField"
                                                placeholder={`${t("Enter_your_Weight")}`}
                                                value={this.state.weight}
                                                type="number"
                                                onChange={(event: React.ChangeEvent<{ value: any }>) => this.handleWeightFieldValueChange(event)}
                                                onKeyPress={(event: any) => { this.handlePositiveDecimalNumberOnKeyPress(event) }}
                                                onKeyDown={(event: any) => { this.disableDownKey(event) }}
                                            />
                                            <ErrorValidationMessage errorState={this.state.weightValidationError} errorMessage={t("Enter_your_Weight")} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Height")}<span className={classes.requerField}>*</span>
                                            </Typography>
                                            <TextField variant='outlined'
                                                className={classes.inputFieldWithLinearGradient}
                                                data-testid="heightField"
                                                placeholder={`${t("Enter_your_Height")}`}
                                                fullWidth
                                                value={this.state.height}
                                                type="number"
                                                onChange={(event: React.ChangeEvent<{ value: any }>) => this.handleHeightFieldValueChange(event)}
                                                onKeyPress={(event: any) => { this.handlePositiveDecimalNumberOnKeyPress(event) }}
                                                onKeyDown={(event: any) => { this.disableDownKey(event) }}
                                            />
                                            <ErrorValidationMessage errorState={this.state.heightValidationError} errorMessage={t("Enter_your_Height")} />
                                        </Grid>


                                        <Grid item xs={12} sm={6} >
                                            {/* here RelationSelectField codes start {rf} */}
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Relation")}<span className={classes.requerField}>*</span>
                                            </Typography>
                                            <RelationSelectField instance={this} MenuProps={MenuProps} />
                                            <ErrorValidationMessage errorState={this.state.relationNameValidationError} errorMessage={t("Select_your_Relation")} />
                                            {/* here RelationSelectField codes end {rf} */}
                                        </Grid>

                                        <Grid item xs={12} sm={6} >
                                            {/* here ActivityLevel select code start {rf} */}
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Activity_Level")}<span className={classes.requerField}>*</span>
                                            </Typography>
                                            <ActivityLevelSelectField instance={this} ActivityLevel={ActivityLevel} MenuProps={MenuProps} />
                                            <ErrorValidationMessage errorState={this.state.activityLevelValidationError} errorMessage={t("Select_your_Activity_Level")} />
                                            {/* here ActivityLevel select code end {rf} */}
                                        </Grid>
                                        <Grid item xs={12} sm={12} >
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Address")}<span className={classes.requerField}>*</span>
                                            </Typography>
                                            <TextField variant='outlined'
                                                className={classes.inputFieldWithLinearGradient}
                                                data-testid="addressField"
                                                placeholder={`${t("Enter_your_Address")}`}
                                                value={this.state.address}
                                                fullWidth
                                                onChange={(event: React.ChangeEvent<{ value: any }>) => this.handleAddressFieldValueChange(event)}
                                            />
                                            <ErrorValidationMessage errorState={this.state.addressValidationError} errorMessage={t("Enter_your_Address")} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Contact_Number")}<span className={classes.requerField}>*</span>
                                            </Typography>
                                            <div className={classes.foodBasketTypeTextField} style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                                <Typography style={{ padding: '0 10px' }}>+91</Typography>
                                                <InputBase
                                                    id="outlined-basic"
                                                    data-testid="contactField"
                                                    placeholder={`${t("Enter_your_Number")}`}
                                                    fullWidth
                                                    value={this.state.contactNumber}
                                                    type="number"
                                                    style={{ backgroundColor: 'transparent', padding: '0.7rem', width: '80%' }}
                                                    onChange={(event: React.ChangeEvent<{ value: any }>) => this.handelContactNumberValueChange(event)}
                                                    onKeyPress={(event: any) => { this.handlePositiveNumberOnKeyPress(event) }}
                                                    onKeyDown={(event: any) => { this.disableDownKey(event) }}
                                                />
                                            </div>

                                            <ErrorValidationMessage errorState={this.state.contactNumberValidationError} errorMessage={t("Enter_your_Number")} />
                                            {
                                                this.state.isContactNumberValid ? <Typography className={classes.errorvalidationText}>{this.state.isContactNumberValid}.</Typography> : ''
                                            }
                                        </Grid>

                                        <Grid item xs={12} sm={6} >
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("Pin_Code")}<span className={classes.requerField}>*</span>
                                            </Typography>

                                            <TextField variant='outlined'
                                                className={classes.inputFieldWithLinearGradient}
                                                data-testid="pinNumberField"
                                                placeholder={`${t("Enter_your_Pin_Code")}`}
                                                fullWidth
                                                value={this.state.pinCode}
                                                type="number"
                                                onChange={(event: React.ChangeEvent<{ value: any }>) => this.handlePinCodeFieldValueChange(event)}
                                                onKeyPress={(event: any) => { this.handlePositiveNumberOnKeyPress(event) }}
                                                onKeyDown={(event: any) => { this.disableDownKey(event) }}
                                            />

                                            <ErrorValidationMessage errorState={this.state.pinCodeValidationError} errorMessage={t("Enter_your_Pin_Code")} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("State")}<span className={classes.requerField}>*</span>
                                            </Typography>
                                            <StateSelectField instance={this} States={States} MenuProps={MenuProps} />
                                            <ErrorValidationMessage errorState={this.state.stateNameValidationError} errorMessage={t("Select_your_State")} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Typography gutterBottom className={classes.foodBasketListTitle}>
                                                {t("City")}<span className={classes.requerField}>*</span>
                                            </Typography>
                                            <CitySelectfield instance={this} MenuProps={MenuProps} />

                                            <ErrorValidationMessage errorState={this.state.cityNameValidationError} errorMessage={t("Select_your_City")} />
                                        </Grid>

                                    </Grid>


                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            data-testid="addNewProfileBtn"
                                            onClick={!this.state.editprofileid ? this.addProfileMember : this.updateNewMemberDetails}
                                            className={classes.productButton} >
                                            {t("Save")}
                                        </Button>
                                    </div>
                                </div>
                            )}


                        </div>

                    </Grid >
                </Grid >

            </div >
        );
    }
}


const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        profileTopBanner: {
            backgroundImage: `url(${addNewMemberPageBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '350px',
            [theme.breakpoints.only("xs")]: {
                height: '80px',
            },
            [theme.breakpoints.only("sm")]: {
                height: '190px',
            },
            [theme.breakpoints.only("md")]: {
                height: '150px',
            },
            [theme.breakpoints.only("lg")]: {
                height: '200px',
            },
        },
        addNewMemberContainer: {
            margin: '1.5rem 25px 1.5rem 25px',
        },
        shortDescription: {
            color: '#455A64',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "33px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "20px",
            },
        },
        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: 5,
                marginRight: 5,
            },
        },
        pageHeadingText: {
            color: '#37006E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "33px",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
        },
        backButtonText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF5050",
            cursor: 'pointer',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        foodBasketTypeTextField: {
            border: "double 1px transparent",
            borderRadius: 8,
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(205deg,#41479B,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            height: '55px',
            [theme.breakpoints.down("sm")]: {
                marginBottom: 16,
                marginLeft: "auto",
                marginRight: "auto",
            },
        },
        foodBasketListTitle: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            color: '#263238',
            [theme.breakpoints.down("sm")]: {
                fontSize: '16px',
            },
        },
        productButton: {
            fontFamily: `'Montserrat', sans-serif`,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            background: '#38006D',
            borderRadius: "44px",
            padding: '16px 110px',
            marginTop: '4rem',
            '&:hover': {
                background: '#12125c',
                border: 'none',
            },
            [theme.breakpoints.down("md")]: {
                padding: '12px 90px',
                fontSize: '16px',
                marginTop: '2.5rem',
            },
            [theme.breakpoints.down("sm")]: {
                padding: '12px 90px',
                fontSize: '14px',
                marginTop: '2rem',
            },
        },
        errorvalidationText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: 'normal',
            fontWeight: 500,
            color: '#FF3939',
            fontSize: '15px',
            marginTop: '0.785rem'
        },
        inputFieldWithLinearGradient: {
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',
            border: "double 1px transparent",
            borderRadius: 8,
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(205deg,#41479B,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            backgroundColor: 'red',
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',
                border: "double 1px transparent",
                borderRadius: 8,
                backgroundImage:
                    "transperant linear-gradient(205deg,#41479B,#FF3939)",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.14)',
                border: "double 1px transparent",
                borderRadius: 8,
                backgroundImage:
                    "transperant linear-gradient(205deg,#41479B,#FF3939)",
            },
        },
        requerField: {
            color: "#FF3939"
        }

    });

// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    withTranslation('UserProfilePageWebLang')(AddNewMemberPageWeb)
);