import React, {useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardMedia, CardContent, Avatar, DialogContent, Dialog } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';



interface Props {
    niqProducts: any,
    goToProductDetailsPage: (id: any) => void;
}

const NiqProducts = ({ niqProducts, goToProductDetailsPage }: Props) => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalClose = () => {
        setModalOpen(false)
    }
    return (
        <div>
            <Grid container spacing={3}>
                {niqProducts.map((item: any, key: any) => {
                    return (
                        <Grid item xs={12} sm={3} md={3} style={{ marginTop: '2rem' }} key={key}>
                            <div className={classes.productImageBorder}>
                                {niqProducts ?
                                    <Card
                                        style={{
                                            boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
                                            cursor: "pointer",
                                            borderRadius: '10px',
                                            padding: '1rem'

                                        }}
                                        data-testid="gotToProductDetailsBtn"
                                        onClick={() => item.attributes.product_rating === 'NA' ? setModalOpen(true) : goToProductDetailsPage(item.attributes.id)}
                                    >
                                        {item.attributes.image ?
                                            <CardMedia component="img" className={classes.media} image={item.attributes.image}
                                                alt={item.attributes.product_name}
                                            /> : <Skeleton variant="rect" width={200} height={100} />}

                                        <CardContent style={{ display: "flex", justifyContent: "space-around", }}>

                                            {item.attributes.product_name ? <Typography
                                                variant="subtitle1"
                                                component="p"
                                                className={classes.productName}>
                                                {item.attributes.product_name}
                                            </Typography> :
                                                <Skeleton variant="text" style={{ width: 100, height: 50, }} />}

                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                                                {item.attributes.product_rating ?
                                                    <Avatar
                                                        aria-label="recipe"
                                                        className={classes.niqAvatarA}
                                                        style={{
                                                            fontSize: 14, background: item.attributes.product_rating === "A" ? "#00AB79" :
                                                                item.attributes.product_rating === "B" ? "#AAC83C" :
                                                                    item.attributes.product_rating === "C" ? "#FCC300" :
                                                                        item.attributes.product_rating === "D" ? "#FF784B" :
                                                                            item.attributes.product_rating === "E" ? "#FF5050" : "#555454"
                                                        }}
                                                    >{item.attributes.product_rating} </Avatar>
                                                    : <Skeleton variant="circle" width={25} height={25} />}
                                                <Typography
                                                    style={{
                                                        fontFamily: `'Montserrat', sans-serif`,
                                                        fontStyle: "normal",
                                                        fontWeight: 500,
                                                        fontSize: "9px",
                                                        textAlign: "left",
                                                        color: "#757D8A",
                                                        marginTop: 5,
                                                    }}>
                                                    N<sup>IQ</sup>&nbsp;Score
                                                </Typography>
                                            </div>
                                        </CardContent>

                                    </Card>
                                    // </Link> 
                                    : <Skeleton variant="rect" width={200} height={100} />
                                }
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
            <Dialog
                open={modalOpen}
                aria-labelledby="form-dialog-title"
                BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
            >
                <div>
                    <MuiDialogTitle disableTypography>
                        <IconButton aria-label="close" className={classes.closeButton} data-testid="modalCloseBtn" onClick={handleModalClose}>
                            <CloseIcon style={{ fontSize: '13px' }} />
                        </IconButton>
                    </MuiDialogTitle>

                    <DialogContent style={{ textAlign: 'center' }}>
                        <Typography className={classes.notificationText}>Please select a product with N<sup>IQ </sup>score</Typography>
                    </DialogContent>

                </div>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    media: {
        height: 180,
        width: '100%',
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
        borderRadius: '10px',

    },
    productName: {
        fontFamily: `'Montserrat', sans-serif`,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "18px",
        height: 50,
        color: "#455A64",
        textAlign: "left",
        whiteSpace: 'break-spaces',
        overflow: "hidden",
        textOverflow: 'ellipsis',
        width: 200,
    },
    niqAvatarA: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: "#00AB79",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(3),
        top: theme.spacing(3),
        color: '#455A64',
        border: '1px solid #455A64',
        padding: '0px'

    },
    notificationText: {
        textAlign: 'center',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "33px",
        margin: '1rem 0',
        color: "#455A64",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "12px",
        },
    },

    productImageBorder: {
        border: "double 1px transparent",
        borderRadius: '10px',
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    productTextPoppins600: {
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 600
    },
    someChoicesProductText: {
        color: '#1D2F5E', fontFamily: `'Poppins', sans-serif`, fontWeight: 600, fontSize: '28px',
        [theme.breakpoints.down("sm")]: {
            fontSize: '18px',
        },
        [theme.breakpoints.only("md")]: {
            fontSize: '22px',
        },
    },

}));

export default NiqProducts