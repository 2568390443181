import React from "react";
// Customizable Area Start
import { Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { heroTopImage, LandingPageBgImage } from "./assets";
import CustomSearch from "../../../components/src/CustomSearch";
import { withTranslation } from "react-i18next";
import HomePageController, { Props } from "./HomepageController";
import HomePageDetailsWeb from "./HomePageDetails.web";
import LanguageDropDrown from "../../../components/src/LanguageDropDrown";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0
    },

    hero__section: {
      height: "100vh",
      marginTop: "64px",
      background: `linear-gradient(109.52deg, rgba(56, 0, 111, 0.82) 55.17%, rgba(255, 57, 57, 0.82) 111.06%), url(${LandingPageBgImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      [theme.breakpoints.down("xs")]: {
        marginTop: 54
      }
    },

    hero__image: {
      margin: "auto",
      height: "auto",
      width: 160,
      [theme.breakpoints.down("md")]: {
        width: 140
      },
      [theme.breakpoints.down("sm")]: {
        width: 120
      },
      [theme.breakpoints.down("xs")]: {
        width: 100
      }
    },

    hero__content: {
      width: "100%",
      height: "100%",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },

    hero_text: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "19px",
      lineHeight: "43px",
      color: "#FFFFFF",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "30px"
      }
    },

    supText: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px"
    },
    MakeNutritionTextContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      columnGap: 50,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column"
      }
    },
    MakeNutritionText: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "21px",
      lineHeight: "26px",
      textAlign: "center",
      color: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        lineHeight: "21px"
      }
    },
    imageContainer: { padding: 40 },
    languageDropDrownContainer: { padding: 40 },
    customSearchContainer: { padding: 15, zIndex: 1 },
    textContainer: { padding: 10 }
  });
// Customizable Area End
class LandingPage extends HomePageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.hero__section}>
          <div className={classes.hero__content}>
            <div className={classes.imageContainer}>
              <img src={heroTopImage} alt="" className={classes.hero__image} />
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.hero_text}>
                {t("homePageText1")}
                <sup className={classes.supText}>{t("IQ")}</sup>{" "}
                {t("homePageText2")}
              </Typography>
            </div>

            <div className={classes.customSearchContainer}>
              {/* @ts-ignore */}
              <CustomSearch />
            </div>

            <div
              className={`${classes.textContainer} ${
                classes.MakeNutritionTextContainer
              }`}
            >
              <Typography className={classes.MakeNutritionText}>
                {t("MakeNutritionEasy")}
              </Typography>
              <Typography className={classes.MakeNutritionText}>
                {t("MakeSmarterFoodChoices")}
              </Typography>
            </div>
            <div className={classes.languageDropDrownContainer}>
              <LanguageDropDrown />
            </div>
          </div>
        </div>
        {/* @ts-ignore */}
        <HomePageDetailsWeb />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(
  withTranslation("homePageLng")(LandingPage)
);
