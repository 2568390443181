import React from 'react'
import { Typography, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Compare } from '../../../blocks/recommendationengine/src/assets';
import '../../../blocks/recommendationengine/src/productDetailsPagestyle.css'
import IndividualProductDeleteAlartDailogBox from '../IndividualProductDeleteAlartDailogBox';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    basketProduct: {
        // width: '90%',
        border: "double 1px transparent",
        borderRadius: '10px',
        backgroundImage:
            "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        marginTop: '2rem'
    },
    healthFoodImage: {
        width: '130px',
        height: '130px',
        [theme.breakpoints.only("xs")]: {
            width: '70px',
            height: '70px',
        }
    },
    healthProductName: {
        color: '#455A64',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 600,
        fontSize: '22px',
        [theme.breakpoints.only("xs")]: {
            fontSize: '13px',
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: '17px',
        },
    },
    healthProductNiqScore: {
        color: '#757D8A',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 600,
        fontSize: '20px',
        [theme.breakpoints.only("xs")]: {
            fontSize: '13px',
        },
        // [theme.breakpoints.only("sm")]: {
        //     fontSize: '17px',
        // },
    },
    productRating: {
        height: '30px',
        width: '30px',

        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 2rem',
        [theme.breakpoints.only('xs')]: {
            height: '25px',
            width: '25px',
        }
    },
    childTextStyle: {
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '18px'
    },
    deleteAndCompareContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center'
    },

}));

interface Props {
    item: any;
    goToProductDetailsPageFunction: (productId: any) => void;
    deleteIndividualFoodBasketProduct: () => void;
    handleIndividualFoodBasketProductDeleteModal: (order_id: any, product_id: any) => void;
    openIndividualFoodBasketProductDeleteModal: boolean;
    handleModalClose: () => void;
    orderId: any;
    addToCompareProducts: any;
}

const HealthProductList = ({
    addToCompareProducts, item, goToProductDetailsPageFunction, deleteIndividualFoodBasketProduct, handleIndividualFoodBasketProductDeleteModal, openIndividualFoodBasketProductDeleteModal, handleModalClose, orderId
}: Props) => {
    const classes = useStyles();
    const [t] = useTranslation(["HealthyFoodBasketWebLang"]);

    return (
        <Grid container spacing={2} className={classes.basketProduct}>
            <Grid item xs={12} sm container >
                <Grid item>
                    {/* <ButtonBase className={classes.image}> */}
                    <img className={classes.healthFoodImage} alt="complex" src={item?.attributes?.product_id?.data?.attributes?.image} />
                    {/* </ButtonBase> */}
                </Grid>
                <Grid item xs container direction="column" spacing={2}>
                    {/* @ts-ignore */}
                    <Grid item xs onClick={(e: any) => goToProductDetailsPageFunction(item?.attributes?.product_id?.data?.attributes?.id)}
                        style={{ cursor: "pointer" }}
                    >
                        <Typography gutterBottom variant="subtitle1" className={classes.healthProductName}>
                            {item?.attributes?.product_id?.data?.attributes?.product_name}
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <Typography variant="body2" gutterBottom className={classes.healthProductNiqScore}>
                                N<sup>IQ</sup> {t("Score")}
                            </Typography>
                            <div className={`${classes.productRating} 
                                                                child_${item?.attributes?.product_id?.data?.attributes?.product_rating} 
                                                                ${classes.childTextStyle} 
                                                                ${`bgColor_${item?.attributes?.product_id?.data?.attributes?.product_rating}`}`}
                            >
                                {item?.attributes?.product_id?.data?.attributes?.product_rating}</div>
                        </div>
                    </Grid>
                </Grid>
                <Grid item className={classes.deleteAndCompareContainer}>

                    <IconButton aria-label="delete" onClick={() => addToCompareProducts(item?.attributes?.product_id)} >
                        <img src={Compare} alt="Compare" style={{
                            filter: item?.attributes?.product_id?.data?.attributes?.compare_product ? 'invert(31%) sepia(100%) saturate(3749%) hue-rotate(345deg) brightness(129%) contrast(104%)' : 'none'
                        }} />
                    </IconButton>
                    <IconButton aria-label="delete" style={{
                        color: '#FF3939',
                    }} onClick={() => handleIndividualFoodBasketProductDeleteModal(orderId, item?.attributes?.product_id?.data?.attributes?.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <IndividualProductDeleteAlartDailogBox isOpen={openIndividualFoodBasketProductDeleteModal} cancleFunction={handleModalClose} deleteFunction={deleteIndividualFoodBasketProduct} />
        </Grid>
    )
}

export default HealthProductList