import React from 'react';
import UserSignUpScreenWebController from './UserSignUpScreenWebController';
import MobilePhoneInputPage from './MobilePhoneInputPage';
import {Grid,Paper,CssBaseline,} from '@material-ui/core';
import {createStyles,withStyles,Theme,} from '@material-ui/core/styles';
import OtpInputPage from './OtpInputPage';
import DescribeInputPage from './DescribeInputPage';
import ImageCarosel from './ImageCarosel';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: '100vh',
            overflowY: 'hidden',
        },        
    });

class UserSignUpScreenWeb extends UserSignUpScreenWebController {
    static UserSignUpScreenWeb: UserSignUpScreenWeb;
    // Customizable Area Start
    render() {
        const {
            loading,            
            mobileNumberInputPageEnable,
            otpInputPageEnable,
            describeInputPageEnable,
            gotErrorResponse
        } = this.state;
        const { classes } = this.props;

        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={false} md={6}>
                    <ImageCarosel {...this.props}/>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    component={Paper}
                    elevation={6}
                    square
                >


                    {mobileNumberInputPageEnable && (
                        <MobilePhoneInputPage
                            sentOtpRequest={this.sentMobileOtpRequest}
                            socialFacebookAuthResponse={this.socialFacebookAuthResponse}
                            socialGoogleAuthResponse={this.socialGoogleAuthResponse}
                            navigationBtnFnc={this.navigationBtnFnc}
                            loading={loading}
                            gotErrorResponse={gotErrorResponse}
                        />
                    )}
                    {otpInputPageEnable && (
                        <OtpInputPage
                            otpVarification={this.mobileOtpVerification}
                            resentOtpRequest={this.resentOtpRequest}
                            pageManageInLocalstore={this.pageManageInLocalstore}
                            navigationBtnFnc={this.navigationBtnFnc}
                            handleContinue={this.handleContinue}
                            loading={loading}
                            inValidOTP={this.state.inValidOTP}
                            isInValidOTP={this.state.isInValidOTP}
                            expireOTP={this.state.expireOTP}
                            isExpireOTP={this.state.isExpireOTP}
                            
                        />
                    )}
                    {describeInputPageEnable && (
                        <DescribeInputPage userProfileUpdate={this.userProfileUpdate} navigationBtnFnc={this.navigationBtnFnc} loading={loading} />
                    )}
                </Grid>
            </Grid>
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    UserSignUpScreenWeb
);
