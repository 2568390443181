import React from 'react';
import Loader from '../../../../components/src/Loader';
import HealthPointWebController from './HealthPointWebController';
import { Typography, Grid } from "@material-ui/core";
import { topHeaderBanner, sideBarBanner } from '../assets';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import HealthProductList from '../../../../components/src/healthComponents/HealthProductList';
import PositiveNagativeHealthPoint from '../../../../components/src/healthComponents/PositiveNagativeHealthPoint';
import { withTranslation } from 'react-i18next';



const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        profileTopBanner: {
            backgroundImage: `url(${topHeaderBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '200px'
        },

        // css for textContainer
        textContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        headdingText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "33px",
            color: "#37006E",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },

        },
        backButtonText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF5050",
            cursor: 'pointer',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        FoodTypeUnderline: {
            textUnderlineOffset: "0.5rem",
            textDecoration: "underline",
            textDecorationColor: "#fb951a61",
        },

        // css for basketContainer
        basketContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },


        TotalProductText: {
            color: '#455A64',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontSize: '25px',
        },


    });

class HealthPointDetailsPageWeb extends HealthPointWebController {
    // Customizable Area Start
    render() {
        const {
            foodProductDetailsLoader,
            foodProductDetails,
            foodProductNutritionValue
        } = this.state;
        const { classes, t } = this.props;
        if (foodProductDetailsLoader) {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <Loader loading={foodProductDetailsLoader} />
                </Grid>
            );
        }

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <div className={classes.profileTopBanner}>
                        </div>

                        <div className={classes.basketContainer}>
                            <div className={classes.textContainer}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography className={`${classes.FoodTypeUnderline} ${classes.headdingText}`} variant='h1'>
                                        {t("Health_Points")}
                                    </Typography>
                                </div>
                                <Typography className={classes.backButtonText} data-testid="backBtn" onClick={() => this.backButton()}>
                                    {t("Back")}
                                </Typography>
                            </div>

                            <PositiveNagativeHealthPoint foodProductNutritionValue={foodProductNutritionValue} />
                            <div style={{ margin: '3rem 0' }}>
                                <Typography className={classes.TotalProductText}> {t("Total_Products")}: {foodProductDetails?.attributes?.product_count}</Typography>
                                {
                                    foodProductDetails?.attributes?.order_id?.data.map((item: any, key: any) => {
                                        return (
                                            <HealthProductList
                                                addToCompareProducts=""
                                                item={item}
                                                goToProductDetailsPageFunction={this.goToProductDetailsPage}
                                                key={key}
                                                deleteIndividualFoodBasketProduct={this.deleteIndividualFoodBasketProduct}
                                                handleIndividualFoodBasketProductDeleteModal={this.handleIndividualFoodBasketProductDeleteModal}
                                                openIndividualFoodBasketProductDeleteModal={this.state.openIndividualFoodBasketProductDeleteModal}
                                                handleModalClose={this.handleModalClose}
                                                orderId={foodProductDetails?.attributes?.id}
                                            />
                                        )
                                    }
                                    )
                                }
                            </div>
                        </div>
                    </Grid >
                </Grid >
            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    withTranslation('HealthyFoodBasketWebLang')(HealthPointDetailsPageWeb)
);