import React from 'react';
import ProductReportedWebController from './ProductReportedWebController';
import { Typography, Grid } from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import CustomizedToggleSwitche from '../../../../components/src/CustomizedToggleSwitche';
import { sideBarBanner, topHeaderBanner1 } from "../assets"
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ProductReportedLists } from './ProductReportedLists';
const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },
        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        profileTopBanner: {
            marginTop: "-1px",
            marginLeft: '-1px',
            [theme.breakpoints.only("sm")]: {
                marginTop: "15px"
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: "8px"
            },
        },
        addNewMemberContainer: {
            margin: '1.5rem 25px 1.5rem 25px',
        },
        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        pageHeadingText: {
            color: '#37006E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "33px",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
        },


    });


class ProductReportedWebPage extends ProductReportedWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
        } = this.state;
        const { classes, t } = this.props;
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} >

                        <img src={topHeaderBanner1} alt="bgImage" style={{ width: "100%" }} className={classes.profileTopBanner} />
                        <div className={classes.addNewMemberContainer}>
                            <CustomizedToggleSwitche checked={this.state.switchChecked} onChange={this.toggleChecked} leftToggleText={t("Your_Products")} rightToggleText={t("Products_Reported")} />
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>{t("Products_Reported")}</Typography>
                            </div>
                            {loading ? <div
                                style={{
                                    textAlign: 'center',
                                    padding: '10px 0'
                                }}
                            >
                                <CircularProgress />
                            </div>
                                : <ProductReportedLists
                                    reportedProductLists={this.state.reportedProductList}
                                    formatTheDate={this.formatTheDate}
                                    pageProps={this.props} />}
                        </div>
                    </Grid >
                </Grid >

            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    withTranslation('ProductDetailsLang')(ProductReportedWebPage)
);