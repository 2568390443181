import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Alert from '@material-ui/lab/Alert';
import { IconButton } from '@material-ui/core';
import Cropper from "react-cropper";
// import "cropperjs/dist/cropper.css";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  uploadIcon: {
    color: "#757D8A", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)"
  },
  imgPreview: {
    overflow: "hidden"
  },
  iconButtonHover: {
    "&:hover": {
      backgroundColor: "#38006D"
    }
  },
  CloudUploadIconHover: {
    color: "#38006D",
    "&:hover": {
      color: "#fff"
    }
  },
  AddAPhotoOutlinedIconHover: {
    "&:hover": {
      color: "#fff",
    }
  }
}));

interface Props {
  croppedImageFunction: any
  removeImage: any
}

const ImageCroppingWeb = ({ croppedImageFunction, removeImage }: Props) => {
  const classes = useStyles();
  const [image, setImage] = useState("");
  const [isValidImage, setIsValidImage] = useState<boolean>(false);
  const [cropper, setCropper] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [result, setResult] = useState<any>("");
  const [orignalImage, setOrignalImage] = useState<any>("");

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.target.files && e.target.files.length > 0) {
      if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
        setIsValidImage(true)
      }
      else {
        setIsValidImage(false)
        setOrignalImage(e.target.files[0])
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
      }
    }
  }
  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setOpen(false);
      const croppedImage = cropper.getCroppedCanvas().toDataURL()
      const convertedImage = base64ToFile(croppedImage, orignalImage?.name);
      setResult(convertedImage)
      croppedImageFunction(convertedImage)
    }
    else {
      setIsValidImage(true)
    }
  };
  const originalImage = () => {
    if (orignalImage) {
      setResult(orignalImage)
      croppedImageFunction(orignalImage)
      setOpen(false);
    }
    else {
      setIsValidImage(true)
    }
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImage("")
    setResult("")
    setOrignalImage("")
    removeImage()
  };

  const handleSnackBarClose = () => {
    setIsValidImage(false)
  }
  const base64ToFile = (base64Data: any, filename: any) => {
    const arr = base64Data.split(',');
    const mime = arr[0].split(':')[1].split(';')[0];
    const bstr = atob(arr[1]);

    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1);
      n -= 1;
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <>

      <Dialog open={open}
        onClose={handleClose}
        style={{ minWidth: "300px", zIndex: 9999 }}>
        <DialogTitle style={{ padding: 8 }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography style={{ fontFamily: `'Montserrat', sans-serif`, fontStyle: "normal", fontWeight: 500, fontSize: "16px", color: "#38006D", textAlign: "center" }} >Image Upload</Typography>
            <IconButton size='small' onClick={handleClose}>
              <HighlightOffIcon style={{ color: "#38006D" }} />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent dividers>

          {isValidImage && <Alert onClose={handleSnackBarClose} severity="error" variant='outlined'>Please Uplaod A Valid Image</Alert>}
          {!image ?
            <>
              <label htmlFor='test' style={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', cursor: "pointer" }} >
                <IconButton component="span" className={classes.iconButtonHover} size='small'>
                  <CloudUploadIcon className={classes.CloudUploadIconHover} fontSize='large' />
                </IconButton>
                <Typography style={{ fontFamily: 'Poppins', fontStyle: "normal", fontWeight: 500, fontSize: "16px", color: "#C4C4C4", textAlign: "center" }}>Upload a Image</Typography>
              </label>
              <input type="file" accept=".png, .jpg, .jpeg" onChange={onChange} id="test" style={{ visibility: 'hidden' }} />
            </>

            : <Cropper
              style={{ height: 400, width: "100%" }}
              zoomTo={0.5}
              initialAspectRatio={1}
              preview={classes.imgPreview}
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance: any) => {
                setCropper(instance);
              }}
              guides={true}
            />}

        </DialogContent>
        <DialogActions>
          <Button style={{ border: "1px solid #38006D", color: "#38006D", fontWeight: 700, textTransform: "none", margin: "0 auto", marginTop: 8 }} onClick={originalImage} size="small" disabled={image ? false : true} variant='outlined'>Original Image</Button>
          <Button style={{ background: "#38006D", color: "#fff", fontWeight: 700, textTransform: "none", margin: "0 auto", marginTop: 8 }} onClick={getCropData} size="small" disabled={image ? false : true} variant='contained'>Crop</Button>
        </DialogActions>
      </Dialog>

      {!result ?
        <IconButton onClick={handleClickOpen} className={classes.iconButtonHover} style={{ color: "#757D8A", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} size={"medium"}><AddAPhotoOutlinedIcon className={classes.AddAPhotoOutlinedIconHover} fontSize='large' /></IconButton>
        :
        <>
          <img alt={"croppedImage"} src={URL.createObjectURL(result)} style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%', }} className={classes.uploadIcon} />
          <IconButton style={{ position: "absolute", left: "88%", color: "#FF3939" }} size="small" onClick={handleClose}><CloseIcon /></IconButton>
        </>
      }


    </>
  );
};

export default ImageCroppingWeb;
