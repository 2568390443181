import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        [theme.breakpoints.up(600)]: {
            marginTop: 45,
        },
        [theme.breakpoints.down(600)]: {
            marginTop: 48,
        },
        [theme.breakpoints.down(515)]: {
            marginTop: 30,
        },
    },
    noProductContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: 'center',
        paddingTop: 50,
        paddingBottom: 50,

        [theme.breakpoints.down("lg")]: {
            paddingTop: 40,
            paddingBottom: 40,
        },
        [theme.breakpoints.down("md")]: {
            paddingTop: 25,
            paddingBottom: 25,
        },
        [theme.breakpoints.down("sm")]: {
            paddingTop: 15,
            paddingBottom: 15,
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: 5,
            paddingBottom: 5,
        },
    },

    containerStyle: {
        width: "100%",

        [theme.breakpoints.down("md")]: {
            marginLeft: 0,
            marginRight: 0,
            paddingBottom: 0,
        },
    },
    AccordionRootStyle: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 30,
        },
    },
    AccordionStyle: {
        marginBottom: 34,
        background: '#FFFFFF',
        border: '0.3px solid #757D8A',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
        borderRadius: '5px',
        [theme.breakpoints.down("sm")]: {
            marginBottom: 16,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    AccordionSummaryStyle: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 22,
        margin: 12,
        color: "#41479B",
        [theme.breakpoints.down("sm")]: {
            fontSize: 16,
            margin: 5,
            fontWeight: 500,
        },
    },
    AccordionDetailStyle: {
        fontFamily: `'Poppins', sans-serif`,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 20,
        lineHeight: 2,
        marginLeft: 30,
        marginRight: 70,
        marginBottom: 24,
        color: "#757D8A",
        [theme.breakpoints.down("sm")]: {
            fontWeight: 400,
            fontSize: 14,
            marginLeft: 0,
            marginRight: 0,
        },
    },
    AccordionDetailBoxStyle: {
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
    },

}));

const ProductDetailsPageFAQ = () => {
    const classes = useStyles();
    const [t] = useTranslation(["ProductDetailsPageFAQLang"]);
    return (
        <div className={classes.root}>
            <div className={classes.noProductContainer}>
                <Grid container justifyContent="center" alignItems="center" >
                    <div className={classes.containerStyle}>
                        <Grid item>
                            <div style={{ flexGrow: 1 }} />
                            <div className={classes.AccordionRootStyle}>
                                <Accordion className={classes.AccordionStyle}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon style={{
                                            color: "#FF3939", fontSize: "40px", borderRadius: "50%",
                                        }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.AccordionSummaryStyle}>
                                            <span style={{
                                                textUnderlineOffset: "0.5rem",
                                                textDecoration: "underline",
                                                textDecorationColor: "",
                                            }}>{t("What_is_RDA")}</span>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.AccordionDetailStyle}>
                                        <div className={classes.AccordionDetailBoxStyle}>
                                            <Typography style={{ fontSize: "inherit" }}>
                                                <span style={{ color: "red", fontWeight: 900 }}>*</span> {t("RDA_or_Recommended_Dietary_Allowance_is_the_daily")}
                                            </Typography>

                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion className={classes.AccordionStyle}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon style={{
                                            color: "#FF3939", fontSize: "40px", borderRadius: "50%",
                                        }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.AccordionSummaryStyle}>
                                            <span style={{
                                                textUnderlineOffset: "0.5rem",
                                                textDecoration: "underline",
                                                textDecorationColor: "#41479B",
                                            }}>{t("Disclaimer")}</span>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.AccordionDetailStyle}>
                                        <div className={classes.AccordionDetailBoxStyle}>
                                            <Typography style={{ fontSize: "inherit" }}>
                                                {t("The")} N<sup>IQ</sup> {t("Score_is_based_on_the_nutrition_facts")}
                                            </Typography>

                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </div>
        </div>
    )
}

export default ProductDetailsPageFAQ;
