Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.applicationJsonContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.deleteAPiMethod = "Delete";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdvancedSearch";
exports.labelBodyText = "AdvancedSearch Body";
exports.smartSearchDynamicFilterApiEndPoint = "bx_block_catalogue/products/smart_search_filters";
exports.favouriteSearchApiEndPoint = "bx_block_catalogue/favourite_searches";
exports.getSearchResultsApiCallIDEndPoint = "bx_block_catalogue/products/product_smart_search";
exports.btnExampleTitle = "CLICK ME";
exports.commaSeperator='/\s*,\s*/g'
exports.colonSeperator='/\s*:\s*/g'
exports.emptySeperator='/\s*"\s*/g'
// Customizable Area End
