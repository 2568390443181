import React from 'react';
import BmiCalculatorWebController from './BmiCalculatorWebController';
import { Typography, Grid } from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import SideNavbarWeb from '../../../../components/src/SideNavbarWeb';
import { sideBarBanner } from '../../../recommendationengine/src/assets'
import CustomizedToggleSwitche from '../../../../components/src/CustomizedToggleSwitche';
import CustomDeleteAlartDailogBox from '../../../../components/src/CustomDeleteAlartDailogBox';
import { topBannerBMI } from "../assets"
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BmiNewProfileMemberLists } from './BmiNewProfileMemberLists';
import { LoadingErrorNotificationText } from '../../../../components/src/LoadingErrorNotificationText';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3rem 0',
            },
        },

        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        profileTopBanner: {
            marginTop: "-1px",
            [theme.breakpoints.only("sm")]: {
                marginTop: "15px"
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: "7px"
            },
        },

        addNewMemberContainer: {
            margin: '1.5rem 25px 1.5rem 25px',
        },
        shortDescription: {
            color: '#455A64',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "33px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "20px",
            },
        },
        headingTextContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            margin: '1.5rem 0 1.5rem 0',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        pageHeadingText: {
            color: '#37006E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: "25px",
            lineHeight: "33px",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "12px",
            },
        },
        backButtonText: {
            fontFamily: `'Poppins', sans-serif`,
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF5050",
            cursor: 'pointer',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
            },
        },
    });



class BmiCalculatorPageWeb extends BmiCalculatorWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
        } = this.state;
        const { classes, t } = this.props;

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbarWeb />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} >

                        <img src={topBannerBMI} alt="bgImage" style={{ width: "100%" }} className={classes.profileTopBanner} />
                        <div className={classes.addNewMemberContainer}>
                            <CustomizedToggleSwitche checked={this.state.switchChecked} onChange={this.toggleChecked} leftToggleText={t("BMIResult")} rightToggleText={t("NutritionalGap")} />
                            <div className={classes.headingTextContainer}>
                                <Typography className={classes.pageHeadingText}>{t("BMICalculator")}</Typography>
                                <Typography className={classes.backButtonText} data-testid="backbtn" onClick={() => this.backButton()}>
                                    {t("Back")}
                                </Typography>
                            </div>

                            <div>
                                <Typography className={classes.shortDescription}>
                                    Body Mass Index is a simple calculation using a person's height and weight. The formula is <br /> BMI = kg/m2 where kg is a person's weight in kilograms and m2 is their height in metres squared.
                                </Typography>
                            </div>
                            {loading ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> :

                                (
                                    <>
                                        {this.state.errorReponse ? <LoadingErrorNotificationText /> :
                                            <BmiNewProfileMemberLists profileLists={this.state.bmiProfileList} handleDeleteModalOpen={this.handleDeleteModalOpen} navigationProps={this.props.navigation} />}
                                    </>
                                )
                            }


                        </div>
                    </Grid >
                </Grid >
                <CustomDeleteAlartDailogBox isOpen={this.state.openDeleteModalBox} cancleFunction={this.handleModalClose} deleteFunction={this.deleteProfileMember} deleteId='' />
            </div >
        );
    }
}
// Customizable Area End
export default withStyles(styles, { withTheme: true })(withTranslation('BMiCalculatorLng')(BmiCalculatorPageWeb));