import React from "react";
// Customizable Area Start
import { Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  aboutImage,
  extensiveDataImage,
  globalGuidelinesImage,
  nutritiveValueImage,
  productAnalysisImage,
  smartSearchImage,
  nutritiveValueBgImage
} from "./assets";
import HomePageController from "./HomepageController";
import { withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    counter_section: {
      width: "100%",
      background: "linear-gradient(90.14deg, #590EA0 3.61%, #FF5050 99.93%)",
      borderBottom: "5px solid #ffff"
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
      background: "none",
      boxShadow: "none",
      color: "white"
    },
    counter_title_text: {
      fontFamily: `'Poppins', sans-serif`,
      fontWeight: 600,
      fontSize: "35px",
      lineHeight: "52px",
      color: "#FFFFFF",
      [theme.breakpoints.down("md")]: {
        fontSize: "25px"
      }
    },
    counter_content_text: {
      fontFamily: `'Poppins', sans-serif`,

      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "27px",
      color: "#FFFFFF",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px"
      }
    },
    about_section: {
      background: `linear-gradient(248.69deg, rgba(54, 0, 109, 0.82) 45.74%, rgba(252, 169, 36, 0.82) 92.73%)`,
      borderBottom: "5px solid #ffff",
      padding: "5rem 0"
    },
    productAnalysis_section: {
      background: `linear-gradient(248.69deg, rgba(54, 0, 109, 0.8) 42.39%, rgba(255, 79, 79, 0.8) 92.73%)`,
      borderBottom: "5px solid #ffff",
      padding: "5rem 0"
    },
    globalGuidelines_section: {
      background: `linear-gradient(248.69deg, rgba(54, 0, 109, 0.8) 42.39%, rgba(138, 65, 206, 0.8) 92.73%)`,
      borderBottom: "5px solid #ffff",
      padding: "5rem 0"
    },
    nutritiveValue_section: {
      background: `linear-gradient(140deg, rgba(54, 0, 109, 0.82) 45.74%, rgba(252, 169, 36, 0.82) 92.73%),url(${nutritiveValueBgImage})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderBottom: "5px solid #ffff",
      padding: "5rem 0",
      backgroundPosition: "center"
    },
    extensiveData_section: {
      background: `linear-gradient(90deg, rgba(8, 17, 152, 0.8) -8.48%, rgba(255, 79, 79, 0.8) 123.7%)`,
      borderBottom: "5px solid #ffff",
      padding: "5rem 0"
    },
    smartSearch_section: {
      background: `linear-gradient(257.5deg, rgba(255, 184, 69) 5.59%, rgba(255, 79, 79) 97.18%)`,
      borderBottom: "5px solid #ffff",
      padding: "5rem 0"
    },
    boxTitleText: {
      fontWeight: 600,
      fontFamily: `'Montserrat', sans-serif`,
      fontSize: "40px",
      lineHeight: "70px",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        lineHeight: "50px"
      }
    },
    UnderlineStyle: {
      marginBottom: 20,
      width: "230px",
      [theme.breakpoints.down("md")]: {
        width: "150px"
      },

      [theme.breakpoints.down("xs")]: {
        width: "150px"
      }
    },
    EDTUnderlineStyle: {
      marginBottom: 20,
      width: "370px",
      [theme.breakpoints.down("md")]: {
        width: "260px"
      }
    },
    SSUnderlineStyle: {
      marginBottom: 20,
      width: "210px",
      [theme.breakpoints.down("md")]: {
        width: "130px"
      },
      [theme.breakpoints.down("xs")]: {
        width: "130px"
      }
    },
    boxContentText: {
      fontWeight: 500,
      fontFamily: `'Montserrat', sans-serif`,
      fontSize: "28px",
      lineHeight: "40px",
      textAlign: "justify",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "28px"
      }
    },
    home_intro_img: {
      width: "65%",
      [theme.breakpoints.only("sm")]: {
        width: "40%"
      },
      [theme.breakpoints.only("xs")]: {
        width: "60%"
      }
    },
    gridContent: {
      color: "#ffff",
      padding: "0 4rem",
      [theme.breakpoints.down("md")]: {
        padding: "0 1rem"
      }
    },
    girdSwap: {
      [theme.breakpoints.down("md")]: {
        order: 2
      }
    },
    yellowBgHeight: { height: "1px", background: "#FCC300" },
    whiteBgHeight: { height: "1px", background: "#FFFFFF" },
    counterContainer: { padding: "45px 0" }
  });
// Customizable Area End
class HomePageDetails extends HomePageController {
  render() {
    const { classes, t } = this.props;
    return (
      <>
        {/* --------- countDown section start {rf} -------------- */}
        <div className={classes.counter_section}>
          <Grid container className={classes.counterContainer}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.counter_title_text}>
                  20,000+
                </Typography>
                <Typography className={classes.counter_content_text}>
                  {t("TotalProductListed")}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.counter_title_text}>
                  {t("MillionsOfSmartSearches1")}
                </Typography>
                <Typography className={classes.counter_content_text}>
                  {t("MillionsOfSmartSearches2")}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.counter_title_text}>
                  10,000+
                </Typography>
                <Typography className={classes.counter_content_text}>
                  {t("DailySearches")}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.counter_title_text}>
                  1,00,000+
                </Typography>
                <Typography className={classes.counter_content_text}>
                  {t("users")}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>

        {/* --------- countDown section end {rf} -------------- */}

        {/* --------- about section start {rf} -------------- */}

        <div className={classes.about_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={aboutImage}
                  alt=""
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  {t("whatsyourN")}
                  <sup>{t("IQ")}</sup>
                </Typography>
                <div
                  // style={{ height: "1px", background: "#FCC300" }}
                  className={`${classes.UnderlineStyle} ${
                    classes.yellowBgHeight
                  }`}
                  // className={classes.UnderlineStyle}
                />
                <Typography className={classes.boxContentText}>
                  {t("WhatsYourNiqDescription")}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* --------- about section end {rf} -------------- */}

        {/* --------- productAnalysis section start {rf} -------------- */}

        <div className={classes.productAnalysis_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={7} className={classes.girdSwap}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  {t("ProductAnalysisTitle")}
                </Typography>
                <div
                  className={`${classes.UnderlineStyle} ${
                    classes.whiteBgHeight
                  }`}
                />
                <Typography className={classes.boxContentText}>
                  {t("ProductAnalysisDescription")}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={productAnalysisImage}
                  alt=""
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        {/* --------- productAnalysis section end {rf} -------------- */}

        {/* --------- globalGuidelines section start {rf} -------------- */}

        <div className={classes.globalGuidelines_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={globalGuidelinesImage}
                  alt=""
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  {t("Global&LocalGuidelinesTitle")}
                </Typography>
                <div
                  className={`${classes.UnderlineStyle} ${
                    classes.whiteBgHeight
                  }`}
                />
                <Typography className={classes.boxContentText}>
                  {t("Global&LocalGuidelinesDescription")}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* --------- globalGuidelines section end {rf} -------------- */}

        {/* --------- nutritiveValue section start {rf} -------------- */}

        <div className={classes.nutritiveValue_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={7} className={classes.girdSwap}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  {t("NutritiveValueScaleTitle")}
                </Typography>
                <Typography className={classes.boxContentText}>
                  {t("NutritiveValueScaleDescription")}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={nutritiveValueImage}
                  alt=""
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        {/* --------- nutritiveValue section end {rf} -------------- */}

        {/* --------- extensiveData section start {rf} -------------- */}

        <div className={classes.extensiveData_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={extensiveDataImage}
                  alt="extensiveDataImage"
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  {t("ExtensiveData&DeepTechTitle")}
                </Typography>
                <div
                  className={`${classes.EDTUnderlineStyle} ${
                    classes.whiteBgHeight
                  }`}
                />
                <Typography className={classes.boxContentText}>
                  {t("ExtensiveData&DeepTechDescription")}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* --------- extensiveData section end {rf} -------------- */}

        {/* --------- smartSearch section start {rf} -------------- */}

        <div className={classes.smartSearch_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={smartSearchImage}
                  alt=""
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  {t("SmartSearchTitle")}
                </Typography>
                <div
                  className={`${classes.SSUnderlineStyle} ${
                    classes.whiteBgHeight
                  }`}
                />
                <Typography className={classes.boxContentText}>
                  {t("SmartSearchDescription")}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* --------- smartSearch section end {rf} -------------- */}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation("homePageLng")(HomePageDetails)
);
