import React from 'react'
import { Typography, Grid, Divider } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import '../../../blocks/recommendationengine/src/productDetailsPagestyle.css'
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    healthPoitGrid: {
        backgroundColor: '#38006D',
        borderRadius: '10px',
        color: '#ffff',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    healthPoint: {
        fontSize: '35px',
        textAlign: "center",
        color: '#FFFFFF',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        [theme.breakpoints.only('sm')]: {
            fontSize: '28px',
        }
    },
    healthPointGainText: {
        textAlign: "center",
        color: '#FFFFFF',
        fontFamily: `'Poppins', sans-serif`,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '2.5rem',
        [theme.breakpoints.only('sm')]: {
            fontSize: '17px',
        }
    },
    productQualityContainer: {
        border: '1px solid #37006E',
        borderRadius: '10px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    productGoodQualityText: {
        textAlign: "center",
        color: '#0EAA58',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 600
    },
    productBadQualityText: {
        textAlign: "center",
        color: '#EF502E',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 600
    },
    listItemName: {
        // textAlign: "center",
        color: '#263238',
        fontFamily: `'Montserrat', sans-serif`,
        fontWeight: 500,
        fontSize: '17px'
    },

}));

interface Props {
    foodProductNutritionValue: any;
}

const PositiveNagativeHealthPoint = ({ foodProductNutritionValue }: Props) => {
    const classes = useStyles();
    const [t] = useTranslation(["PositiveNagativeHealthPointLang"]);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} style={{ marginTop: '1.5rem' }}>
                <div className={`${classes.paper} ${classes.healthPoitGrid}`} >
                    <Typography className={classes.healthPoint}>+--</Typography>
                    <Typography className={classes.healthPointGainText}>{t("Health")} <br /> {t("Points_Gain")}</Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} style={{ marginTop: '1.5rem' }}>
                <div className={`${classes.paper} ${classes.productQualityContainer}`}>
                    <Grid container spacing={3}>

                        <Grid item xs={5}>
                            <Typography className={classes.productGoodQualityText}> <i className="fa-solid fa-face-laugh-beam"></i>  {t("Positives")} ({foodProductNutritionValue?.positive_points})</Typography>
                        </Grid>
                        <Grid item xs={2}
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Divider orientation="vertical" style={{ backgroundColor: "#38006D", width: "2%" }} />
                        </Grid>
                        <Grid item xs={5}>
                            <Typography className={classes.productBadQualityText}><i className="fa-solid fa-face-frown-open"></i> {t("Negatives")} ({foodProductNutritionValue?.negative_points})</Typography>
                        </Grid>

                    </Grid>
                    <Grid container spacing={3}>

                        <Grid item xs={5}>
                            <Typography className={classes.listItemName}>
                                {t("Energy")}: {foodProductNutritionValue?.positive?.energy}
                            </Typography>
                            <Typography className={classes.listItemName}>
                                {t("Vitamin")}: {foodProductNutritionValue?.positive?.minerals}
                            </Typography>
                            <Typography className={classes.listItemName}>
                                {t("Minerals")}: {foodProductNutritionValue?.positive?.vitamin}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Divider orientation="vertical" style={{ backgroundColor: "#FF3939", width: "2%" }} />
                        </Grid>
                        <Grid item xs={5}>
                            <Typography className={classes.listItemName}>
                                {t("Fat")}: {foodProductNutritionValue?.negative?.fat}
                            </Typography>
                            <Typography className={classes.listItemName}>
                                {t("Sugar")}: {foodProductNutritionValue?.negative?.sugar}
                            </Typography>

                        </Grid>

                    </Grid>

                </div>
            </Grid>

        </Grid>
    )
}

export default PositiveNagativeHealthPoint